import { MutationTree } from 'vuex';

import type { State } from './state';
import { getLocalStorageKey } from './utils';
import { getItemFromLocalStorage, setItemInLocalStorage } from '@/utils';

export const LOCAL_STORAGE_SET = 'LOCAL_STORAGE_SET';
export const LOCAL_STORAGE_REFRESH = 'LOCAL_STORAGE_REFRESH';

export type Mutations<S = State, P = unknown> = {
  [LOCAL_STORAGE_SET](state: S, payload: P): void;
  [LOCAL_STORAGE_REFRESH](state: S, payload: P): void;
};

export type MutationPayloads = {
  [LOCAL_STORAGE_SET]: {
    key: string,
    value: string | null | Record<string, unknown>;
  };
  [LOCAL_STORAGE_REFRESH]: {
    key: string;
  };
};

export const mutations: MutationTree<State> & Mutations = {
  [LOCAL_STORAGE_SET](state: State, payload: MutationPayloads[typeof LOCAL_STORAGE_SET]) {
    if (payload.value === null) {
      localStorage.removeItem(getLocalStorageKey(payload.key));
    } else {
      const savedPayload = setItemInLocalStorage(getLocalStorageKey(payload.key), payload.value);

      state[payload.key] = savedPayload;
    }
  },
  [LOCAL_STORAGE_REFRESH](state: State, payload: MutationPayloads[typeof LOCAL_STORAGE_REFRESH]) {
    state[payload.key] = getItemFromLocalStorage<string>(getLocalStorageKey(payload.key)) ?? 'false';
  },
};
