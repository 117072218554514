import { bundleVersion } from './constants.js';

export const getEditorConfig = (editorConfigParamsObj) => {
  const { initialEditorData } = editorConfigParamsObj;
  const editorConfig = {
    toolbar: [
      'bold',
      'italic',
      'underline',
      'strikethrough',
      'link',
      'removeFormat',
      '|',
      'highlight:yellowMarker',
      'highlight:blueMarker',
      'removeHighlight',
      '|',
      'bulletedList',
      'numberedList',
      '|',
      'undo',
      'redo',
      'findAndReplace',
    ],
    table: {
      contentToolbar: [],
    },
    initialData: initialEditorData,
    language: 'en',
    highlight: {
      options: [
        {
          model: 'yellowMarker',
          class: 'marker-yellow',
          title: 'Highlight',
          color: '#cac407',
          type: 'marker',
        },
        {
          model: 'blueMarker',
          class: 'marker-blue',
          title: 'Highlight',
          color: '#0288d1',
          type: 'marker',
        },
      ],
    },
    link: {
      // Automatically add target="_blank" and rel="noopener noreferrer" to all external links.
      addTargetToExternalLinks: true,
      defaultProtocol: 'http://',
    },
  };
  return editorConfig;
};
export { bundleVersion };
export default {
  bundleVersion,
  getEditorConfig,
};
