import type { Event } from '@sentry/browser';

/**
 * @param event - sentry event object
 * @param userAgent - the userAgent you wish to check
 * @returns - True / False depending on whether user is using the userAgent
 */
const hasUserAgent = (event: Event, userAgent: string) => (
  window.navigator.userAgent.includes(userAgent) // Check against user's useragent
  || event?.request?.headers?.['User-Agent'].includes(userAgent) // Check against sentry error object
);

export default hasUserAgent;
