import { App } from 'vue';
import { Router } from 'vue-router';

import { Views } from '@/router/router.config';
import { useStore } from '@/store';
import { AuthGetters } from '@/store/modules/auth/auth.types';

function checkPermissions(router: Router): void {
  const { useGetter } = useStore();

  router.beforeResolve(async (to, _from, next) => {
    try {
      const requiredPermissions: string[] = to.meta.requiredPermissions as string[];

      // If no required permissions defined then allow navigation
      if (!requiredPermissions?.length) return next();

      // If user not authenticated then redirect to unauthorised
      const isAuthenticated = useGetter<ReturnType<AuthGetters['authenticated']>>('auth/authenticated');

      if (!isAuthenticated) throw Error('User not authenticated');

      const userCollaborationPermissions = useGetter<
        ReturnType<AuthGetters['userCollaborationPermissions']>
      >('auth/userCollaborationPermissions').join('');

      const userHasRequiredPermissions = requiredPermissions.every(
        (permission) => userCollaborationPermissions.includes(permission),
      );

      // If user does not have required permissions then redirect to unauthorised
      if (!userHasRequiredPermissions) throw Error('User is unauthorised to view this page');

      return next();
    } catch (e) {
      return next({
        name: Views.UNAUTHORISED,
      });
    }
  });
}

export default {
  install: (_app: App, options: { router: Router; }): void => {
    const { router } = options;

    checkPermissions(router);
  },
};
