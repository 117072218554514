
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'AuditHistoryIcon',
  props: {
    title: {
      type: String,
      required: false,
      default: 'Audit history icon',
    },
  },
});
