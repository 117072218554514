import { ok, err, type Result } from 'neverthrow';

export const safeStringify = (obj: any): Result<string, TypeError> => {
  try {
    return ok(JSON.stringify(obj));
  } catch (e: unknown) {
    return err((e as TypeError));
  }
};

export const safeParse = <T>(jsonString: string): Result<T, SyntaxError> => {
  try {
    return ok(JSON.parse(jsonString));
  } catch (e: unknown) {
    return err((e as SyntaxError));
  }
};
