import { useMutation } from '@vue/apollo-composable';
import type { DocumentNode, OperationVariables } from '@apollo/client';
import { unref, type Ref } from 'vue';

import useSelectedProduct from '@/composables/useSelectedProduct';

import type {
  PrimitiveValue,
  PrimitiveValueVec,
} from '@/types/utilityTypes';

type MutationMap = {
  [key: string]: DocumentNode;
};
type UseMutationDoc = Parameters<typeof useMutation>[0];
type UseMutationOptions = Parameters<typeof useMutation>[1];

export function getProductMutation(mutations: MutationMap) {
  type MutationKey = keyof typeof mutations;

  return (
    selectedProduct: MutationKey | Ref<MutationKey>,
  ): DocumentNode => mutations[unref(selectedProduct)];
}

function useProductMutation<
  TResult = any,
  TVariables = UseMutationOptions,
  TOptions = Record<string, PrimitiveValue | PrimitiveValueVec>
>(
  mutation: UseMutationDoc,
  variables?: TVariables,
  options?: TOptions,
) {
  const { selectedProduct } = useSelectedProduct();
  const clientId = (unref(selectedProduct)) ? (unref(selectedProduct) as string) : 'default';

  return (variables)
    ? useMutation<TResult, OperationVariables>(mutation, {
      errorPolicy: 'none',
      fetchPolicy: 'network-only',
      ...options,
      clientId,
      variables: variables as OperationVariables,
    })
    : useMutation<TResult>(mutation, {
      errorPolicy: 'none',
      fetchPolicy: 'network-only',
      ...options,
      clientId,
    });
}

export default useProductMutation;
