const LOCAL_STORAGE_PREFIX = 'vuex-ls:';

export const getLocalStorageKey = (key: string): string => `${LOCAL_STORAGE_PREFIX}${key}`;

export const getSavedLocalStorage = (): Record<string, string> => {
  const entries = Object.entries(localStorage);
  const result = entries.reduce<Record<string, string>>((accumulator, [key, value]) => {
    const isSavedFromVuex = key.startsWith(LOCAL_STORAGE_PREFIX);

    if (isSavedFromVuex) {
      const keyWithoutPrefix = key.substring(LOCAL_STORAGE_PREFIX.length);

      return { ...accumulator, [keyWithoutPrefix]: value };
    }

    return accumulator;
  }, {});

  return result;
};
