export const dateWithoutTimezone = (date: Date) => {
  const userTimezoneOffset = date.getTimezoneOffset() * 60000;

  return new Date(date.getTime() - userTimezoneOffset);
};

// Strip timezones and set time to 00:00:00
export const getStartDate = (date: Date): Date => (
  new Date(dateWithoutTimezone(new Date(date)).setUTCHours(0, 0, 0, 0))
);

// Strip timezones and set time to 23:59:59
export const getEndDate = (date: Date): Date => (
  new Date(dateWithoutTimezone(new Date(date)).setUTCHours(23, 59, 59, 999))
);

/**
 * Accepts a date object as a parameter and returns the
 * formatted date as a string with the following format:
 *
 * E.g.: 2022-1-17
 *
 * @param date - Date to be formatted
 *
 * @returns - Formatted date as string
 */
export function formatDate(date: Date): string {
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();

  return `${year}-${month}-${day}`;
}

/**
 * @returns - Future date as an `Date` object
 */
export function getDatePlus(plusDays: number): Date {
  const date = new Date();

  date.setDate(date.getDate() + plusDays);

  return date;
}

/**
 * Returns tomorrow's date as an `Date` object
 *
 * @returns - Tomorrow's date as an `Date` object
 */
export function getTomorrowsDate(): Date {
  return getDatePlus(1);
}
