import { ApolloError } from '@apollo/client';
import { computed } from 'vue';
import type { Ref, ComputedRef } from 'vue';

import useProductQuery from './useProductQuery';

import DocumentJurisdictionsQuery from '@/shared/graphql/query/DocumentJurisdictions.gql';
import type {
  DocumentJurisdictionsQuery as DocumentJurisdictionsQueryResultType,
  Location,
} from '@/generated/shared-graphql';
import type { LabelValue } from '@/types/utilityTypes';

export type DocumentJurisdictionsQueryResult = {
  documentJurisdictions: ComputedRef<Readonly<Location[]>>;
  loading: Ref<boolean>;
  error: Ref<ApolloError | null>;
}

interface DocumentCycleJurisdictionsComposable {
  fetchDocumentJurisdictions: () => DocumentJurisdictionsQueryResult;
  mapDocumentJurisdictions: (
    jurisdictions: Readonly<Location[]>
  ) => LabelValue[];
}

const useDocumentCycleJurisdictions = (): DocumentCycleJurisdictionsComposable => {
  const fetchDocumentJurisdictions = (): DocumentJurisdictionsQueryResult => {
    const { result, loading, error } = useProductQuery<
      DocumentJurisdictionsQueryResultType
    >(DocumentJurisdictionsQuery, null, {
      errorPolicy: 'all', fetchPolicy: 'network-only',
    });
    const documentJurisdictions = computed(
      () => result.value?.locations?.filter(Boolean) as Location[] ?? [],
    );

    return {
      documentJurisdictions,
      loading,
      error,
    };
  };

  const mapDocumentJurisdictions = (
    jurisdictions: Readonly<Location[]>,
  ): LabelValue[] => {
    const map = jurisdictions.map((jurisdiction) => ({
      value: jurisdiction?.fields?.[0]?.id,
      label: jurisdiction?.fields?.[0]?.name,
    }));

    return map.filter((jurisdiction) => jurisdiction.value) as LabelValue[];
  };

  return {
    fetchDocumentJurisdictions,
    mapDocumentJurisdictions,
  };
};

export default useDocumentCycleJurisdictions;
