import type { MutationTree } from 'vuex';
import { nanoid } from 'nanoid';

import type { State } from './state';
import type { ActiveProduct } from '@/config';

// Mutation triggers
export const APP__ADD_SNACKBAR = 'APP__ADD_SNACKBAR';
export const APP__REMOVE_SNACKBAR = 'APP__REMOVE_SNACKBAR';
export const APP__SET_CLIENT = 'APP__SET_CLIENT';
export const APP__SET_CLIENT_ENDPOINT = 'APP__SET_CLIENT_ENDPOINT';
export const APP__SET_DARK_MODE = 'APP__SET_DARK_MODE';
export const APP__SET_SELECTED_PRODUCT = 'APP__SET_SELECTED_PRODUCT';

// Mutation contracts
export type Mutations<S = State, P = unknown> = {
  [APP__ADD_SNACKBAR](state: S, payload: P): void;
  [APP__REMOVE_SNACKBAR](state: S, payload: P): void;
  [APP__SET_CLIENT](state: S, payload: P): void;
  [APP__SET_CLIENT_ENDPOINT](state: S, payload: P): void;
  [APP__SET_DARK_MODE](state: S, payload: P): void;
  [APP__SET_SELECTED_PRODUCT](state: S, payload: P): void;
};

export type MutationPayloads = {
  [APP__ADD_SNACKBAR]: Omit<State['snackbars'][0], 'id'>;
  [APP__REMOVE_SNACKBAR]: string;
  [APP__SET_CLIENT]: string;
  [APP__SET_CLIENT_ENDPOINT]: string;
  [APP__SET_DARK_MODE]: boolean;
  [APP__SET_SELECTED_PRODUCT]: ActiveProduct | null;
};

// Define mutations
export const mutations: MutationTree<State> & Mutations = {
  [APP__SET_SELECTED_PRODUCT](
    state: State,
    payload: MutationPayloads[typeof APP__SET_SELECTED_PRODUCT],
  ) {
    state.selectedProduct = payload;
  },
  [APP__SET_DARK_MODE](state: State, payload: MutationPayloads[typeof APP__SET_DARK_MODE]) {
    state.darkMode = payload;
  },
  [APP__ADD_SNACKBAR](state: State, payload: MutationPayloads[typeof APP__ADD_SNACKBAR]) {
    const newSnackbar = {
      id: nanoid(),
      messageContent: payload.messageContent,
      classes: payload.classes || '',
      processes: payload.processes || [],
      timeout: payload.timeout,
      icon: payload.icon || {},
      type: payload.type,
    };
    state.snackbars = [...state.snackbars, newSnackbar];
  },
  [APP__REMOVE_SNACKBAR](state: State, payload: MutationPayloads[typeof APP__REMOVE_SNACKBAR]) {
    state.snackbars = state.snackbars.filter((snackbar) => snackbar.id !== payload);
  },
  [APP__SET_CLIENT_ENDPOINT](
    state: State,
    payload: MutationPayloads[typeof APP__SET_CLIENT_ENDPOINT],
  ) {
    state.clientEndpoint = payload;
  },
  [APP__SET_CLIENT](
    state: State,
    payload: MutationPayloads[typeof APP__SET_CLIENT],
  ) {
    state.clientEndpoint = payload;
  },
};
