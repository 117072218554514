export function logError(e: Error | unknown, devOnly = true) {
  if ((process?.env?.VUE_APP_environmentName ?? '').toLowerCase() === 'development' || devOnly === false) {
    console.error(e);
  }
}

export function log(message: string, devOnly = true) {
  if (
    (process?.env?.VUE_APP_environmentName ?? '').toLowerCase() === 'development'
    || devOnly === false
  ) {
    console.log(message);
  }
}
