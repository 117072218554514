/* eslint no-param-reassign: "error" */
import { ApplicationInsights } from '@microsoft/applicationinsights-web';

/**
 * Track route changes as page views with Azure Application Insights
 * @param options
 */
function setupPageTracking(options: any, Vue: any) {
  const { router } = options;
  const pagePrefix = options.pagePrefix || 'Vue App';

  router.beforeEach((route: any, _from: any, next: any) => {
    const name = `${pagePrefix} - ${route.name}`;

    Vue.appInsights.startTrackPage(name);
    next();
  });

  router.afterEach((route: any) => {
    const name = `${pagePrefix} - ${route.name}`;

    Vue.appInsights.stopTrackPage(name);
  });
}

/**
 * @param Vue
 * @param options Object
 * {
 *   instrumentationKey, // Your Application Insights instrumentation key.
 *   appInsightsOptions, // Any additional application insights config options.
 *   trackInitialPageView, // Boolean - whether to track the first page view on load.
 *   pagePrefix // Prefix for page view events. Defaults to (Vue App)
 *  }
 */
function install(Vue: any, options: any) {
  const { instrumentationKey = '', appInsightsOptions = {} } = options;

  if (!instrumentationKey) return;

  if (options.appInsights) {
    Vue.appInsights = options.appInsights;
  } else {
    Vue.appInsights = new ApplicationInsights({
      config: {
        instrumentationKey,
        enableAutoRouteTracking: true,
        ...appInsightsOptions,
      },
    });
    Vue.appInsights.loadAppInsights();
  }

  const { router } = options;

  // Watch route event if router option is defined.
  if (router) {
    if (options.trackInitialPageView) {
      setupPageTracking(options, Vue);
    } else {
      router.onReady(() => setupPageTracking(options, Vue));
    }
  }

  // Object.defineProperty(Vue.prototype, '$appInsights', {
  //   get: () => Vue.appInsights,
  // });
}

export default install;
