import { ApolloClients } from '@vue/apollo-composable';
import {
  createApp, h, provide,
} from 'vue';

import { getAllClients } from '@/apollo';

import appInsightsPlugin from '@/plugins/appInsightsPlugin';
import authPlugin from '@/plugins/authPlugin';
import permissionsPlugin from '@/plugins/permissionsPlugin';
import productAccessPlugin from '@/plugins/productAccessPlugin';
import serviceWorkerUpdatePlugin from '@/plugins/serviceWorkerUpdatePlugin';

import App from './App.vue';
import ErrorHandling from './errorHandling';
import router from './router';
import setupSentry from './sentry';
import store, { storeKey } from './store';
import worker from './worker';

ErrorHandling.init();

function setupApollo(): void {
  const apolloClients = getAllClients();
  const hasAtLeastOneClient = (): boolean => (Object.keys(apolloClients).length > 0);

  if (hasAtLeastOneClient()) {
    provide(ApolloClients, apolloClients);
  }
}

function setupWorker() {
  worker.register();
  worker.askPermission();

  provide('worker', worker);
}

createApp({
  setup() {
    setupApollo();
    setupSentry();
    setupWorker();
  },
  render: () => h(App),
})

  .use(router)
  .use(store, storeKey)
  .use(appInsightsPlugin, {
    instrumentationKey: process.env.VUE_APP_INSIGHTS_INSTRUMENTATION_KEY,
    trackInitialPageView: true,
    pagePrefix: 'Collaboration Portal Vue App',
    router,
  })
  .use(authPlugin, { router })
  .use(permissionsPlugin, { router })
  .use(productAccessPlugin, { router })
  .use(serviceWorkerUpdatePlugin, { router })
  .mount('#app');
