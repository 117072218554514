import { Result } from 'neverthrow';
import * as Sentry from '@sentry/browser';
import { BrowserTracing } from '@sentry/tracing';
import { CaptureConsole } from '@sentry/integrations';
import type { Event as SentryEvent, Breadcrumb } from '@sentry/browser';
import type { ElementOf } from 'ts-essentials';

import { getEnvVarKeyed, hasUserAgent } from '@/utils';
import type { StringDict } from '@/types/utilityTypes';

const REQUIRED_SENTRY_VARS = <const>[
  'VUE_APP_BUILD',
  'VUE_APP_ENVIRONMENT_NAME',
  'VUE_APP_SENTRY_DSN',
  'VUE_APP_SENTRY_SAMPLE_RATE',
];

type SentryVarsRecord = Record<ElementOf<typeof REQUIRED_SENTRY_VARS>, string>;

/**
 * Do not track events from likely crawler/bot requests
 *
 * @param sentryEvent - Sentry event object
 * @returns - Sentry event object or null if the event should be dropped
 */
function beforeSend(sentryEvent: SentryEvent) {
  if (hasUserAgent(sentryEvent, 'HeadlessChrome')) return null;

  return sentryEvent;
}

/**
 * @param breadcrumb - Sentry breadcrumb object
 * @returns - Sentry breadcrumb object or null if the breadcrumb should be dropped
 */
function beforeBreadcrumb(breadcrumb: Breadcrumb) {
  // Prevent console breadcrumbs from being sent
  // As with CKEditor Errors, it would cause too big of a payload, which would result
  // in a 413 error when trying to send over the sentry log
  if (breadcrumb.category === 'console') {
    return null;
  }

  return breadcrumb;
}

/**
 * @returns A object of environment variables or null if all of the required environment
 * variables are not set
 */
function getSentryVars(): StringDict | null {
  const sentryVars = REQUIRED_SENTRY_VARS.map(getEnvVarKeyed);
  const sentryKeys = Result.combine(sentryVars) as Result<SentryVarsRecord[], Error>;

  if (sentryKeys.isErr()) {
    return null;
  }

  return sentryKeys.value.reduce((acc, curr) => ({ ...acc, ...curr }), {});
}

/**
 * Initialise the Sentry object and start tracking errors
 */
function createSentryInstance(): void {
  const sentryVars = getSentryVars() as SentryVarsRecord | null;

  if (sentryVars) {
    Sentry.init({
      attachStacktrace: true,
      autoSessionTracking: true,
      debug: sentryVars.VUE_APP_ENVIRONMENT_NAME === 'development',
      dsn: sentryVars.VUE_APP_SENTRY_DSN,
      environment: sentryVars.VUE_APP_ENVIRONMENT_NAME,
      integrations: [
        new BrowserTracing(),
        new CaptureConsole({
          levels: ['error'],
        }),
      ],
      release: sentryVars.VUE_APP_BUILD,
      sampleRate: parseFloat(sentryVars.VUE_APP_SENTRY_SAMPLE_RATE) || 1,
      tracesSampleRate: parseFloat(sentryVars.VUE_APP_SENTRY_SAMPLE_RATE) || 1,
      beforeBreadcrumb,
      beforeSend,
    });
  }
}

export default createSentryInstance;
