/**
 * Ensures CKEditor convensions are sanitised
 *
 * @param {string} content
 */
const sanitizeEditorContent = (content: string) => content
  .replaceAll('<p>&nbsp;</p>', '<p></p>') // CKEditor sometimes represents blank lines as <p>&nbsp;</p> instead of <p></p> (as per contentstack)
  .replaceAll('&nbsp;', ' '); // CKEditor sometimes represents spaces as &nbsp;

export default sanitizeEditorContent;
