const formattingXslxOptions = {
  'Status export': {
    columns: {
      width: 30,
    },
    headerRows: {
      style: {
        font: { bold: true },
        fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFCCCCCC' } },
      },
      border: {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
      },
    },
    detailRows: {
      alignment: { wrapText: true, vertical: 'top' },
      border: {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
      },
    },
  },
};

const xlsxOptions = {
  sheets: formattingXslxOptions,
  firstSheetName: 'Results',
};

export default xlsxOptions;
