import {
  computed,
  unref,
  type ComputedRef,
  type Ref,
} from 'vue';
import type { RouteRecordRaw } from 'vue-router';
import { utilities } from '@simmons/product-platform-config';

import type { ActiveProduct } from '@/config';
import { ProductLabels } from '@/config/constants';
import {
  excludeNullish,
  getPermissionProducts,
  isActiveProduct,
  removePortalSuffix,
  toPortalProduct,
} from '@/utils';
import getAllRoutes from '@/utils/getAllRoutes';

import { useStore } from '@/store';
import { AuthGetters } from '@/store/modules/auth/auth.types';

const { resolveSpecialLocationPermissionMapping } = utilities;

type ProductReturn = ActiveProduct | null;

type ProductOption = {
  icon: Record<string, unknown> | null;
  label: string;
  value: ActiveProduct;
};

type UseUserAccessReturnType = {
  canAccessMoreThanOneProduct: Ref<boolean>;
  cannotAccessAnyProduct: Ref<boolean>;
  isDocumentOwner: ComputedRef<boolean>;
  isOwner: Ref<boolean>;
  productsUserCanAccess: ProductOption[];
  routesUserCanAccess: RouteRecordRaw[];
  locationPermissionFormat: ComputedRef<string>;
};

export function useUserCollabPermissions() {
  const { useGetter } = useStore();

  return useGetter<
    ReturnType<AuthGetters['userCollaborationPermissions']>
  >('auth/userCollaborationPermissions');
}

const getValidProductsUserCanAccess = (
  usersProducts: ActiveProduct[],
): ProductOption[] => usersProducts.map((product) => ({
  label: ProductLabels[product],
  value: product,
  icon: null,
}));

function getProductsUserCanAccess(userCollabPermissions: string[]): ProductOption[] {
  const userProducts: ActiveProduct[] = getPermissionProducts(userCollabPermissions)
    .map(toPortalProduct)
    .filter(excludeNullish)
    .filter(isActiveProduct);

  return getValidProductsUserCanAccess(userProducts);
}

function getRoutesUserCanAccess(userCollabPermissions: string[]): RouteRecordRaw[] {
  const allAvailableRoutes: RouteRecordRaw[] = getAllRoutes();

  return allAvailableRoutes.filter((routeRecord) => {
    if (routeRecord.meta?.requiredPermissions) {
      return (routeRecord.meta.requiredPermissions as string[]).every(
        (routePermission) => userCollabPermissions.some(
          (permissionString: string) => permissionString.includes(routePermission),
        ),
      );
    }

    return false;
  });
}

function getLocationPermissionFormat(product: ActiveProduct, location: string): string {
  const locationFormat = location.toLowerCase();
  const mappedPermission = resolveSpecialLocationPermissionMapping(product, locationFormat);
  return mappedPermission.replace(/[^\w\s]/gi, '').replace(/ /g, '-');
}

function getIsDocumentOwner(
  product: ActiveProduct,
  location: string,
  userCollabPermissions: string[],
): boolean {
  if (
    !location
    || !product
    || !userCollabPermissions.length
  ) return false;

  const platformProduct = product && removePortalSuffix(product);
  const permissionLocation = getLocationPermissionFormat(product, location);
  const permission = `|${platformProduct}-collaboration-owner:${permissionLocation}`;
  const isOwnerD = userCollabPermissions.find((item) => item.endsWith(permission));

  return !!isOwnerD;
}

function getIsOwner(product: ProductReturn, userCollabPermissions: string[]): boolean {
  const platformProduct = product && removePortalSuffix(product);

  if (!platformProduct || !userCollabPermissions.length) return false;

  const hasOwnerPermission = userCollabPermissions.find(
    (item: string) => item.includes(platformProduct) && item.includes('owner'),
  );

  return !!hasOwnerPermission;
}

export default function useUserAccess(
  product: Ref<ProductReturn> | ComputedRef<ProductReturn> | ProductReturn,
  location?: Ref<string> | ComputedRef<string>,
): UseUserAccessReturnType | never {
  if (!product || unref(product) === null) {
    throw new Error('useUserAccess requires a valid active product');
  }

  const userCollabPermissions = useUserCollabPermissions();

  const productsUserCanAccess: ProductOption[] = getProductsUserCanAccess(userCollabPermissions);
  const routesUserCanAccess: RouteRecordRaw[] = getRoutesUserCanAccess(userCollabPermissions);
  const isDocumentOwner = computed(() => {
    const locationValue = unref(location) ?? null;
    const productValue = unref(product) ?? null;

    if (!locationValue || !productValue) return false;

    return getIsDocumentOwner(productValue, locationValue, userCollabPermissions);
  });
  const isOwner = computed(() => getIsOwner(unref(product), userCollabPermissions));
  const canAccessMoreThanOneProduct = computed(() => productsUserCanAccess.length > 1);
  const cannotAccessAnyProduct = computed(() => productsUserCanAccess.length === 0);
  const locationPermissionFormat = computed(() => {
    const locationValue = unref(location) ?? null;
    const productValue = unref(product) ?? null;

    if (!locationValue || !productValue) return '';

    return getLocationPermissionFormat(productValue, locationValue);
  });

  return {
    canAccessMoreThanOneProduct,
    cannotAccessAnyProduct,
    isDocumentOwner,
    isOwner,
    locationPermissionFormat,
    productsUserCanAccess,
    routesUserCanAccess,
  };
}
