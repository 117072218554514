import { ApolloLink } from '@apollo/client/core';

import router from '@/router';
import { Views } from '@/router/router.config';
import { getErrorsFromArray } from '@/utils/getGraphQLErrorMessages';
import { isArray } from '@simmons/component-library/src/utils/typeGuards';

const notAuthorisedMiddleware = new ApolloLink((
  operation,
  forward,
) => forward(operation).map((response) => {
  const errors = getErrorsFromArray(response.errors || []);
  const isEveryErrorUnauthorised = errors.every((string) => string === 'Not Authorised!');

  if (errors.length === 0 || !isEveryErrorUnauthorised) {
    return response;
  }

  let responseData = null;
  if (response.data) {
    let dataItem = response.data;
    while (typeof dataItem !== 'string' && Object.keys(dataItem).length > 0 && !responseData) {
      if (isArray(dataItem[Object.keys(dataItem)[0]])) {
        responseData = dataItem[Object.keys(dataItem)[0]];
      } else {
        dataItem = dataItem[Object.keys(dataItem)[0]];
      }
    }
  }

  const hasNoData = response.data === undefined || response.data === null
    || responseData?.every((item: any) => item === null);

  if (isEveryErrorUnauthorised && hasNoData) {
    router.push({ name: Views.UNAUTHORISED });

    return response;
  }

  return response;
}));

export default notAuthorisedMiddleware;
