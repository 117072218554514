import { ApolloError } from '@apollo/client/core';
import { GraphQLError } from 'graphql';

type NestedGqlError = GraphQLError & {
  errors: Array<{
    message: string;
  }>,
};

export const getErrorsFromArray = (errors: ReadonlyArray<GraphQLError | NestedGqlError>) => {
  if (errors.length === 0) {
    return [];
  }

  return errors.reduce<Array<string>>((accumulator, e) => {
    const errorMessage = e.message;

    if (errorMessage) {
      return accumulator.concat([errorMessage]);
    }

    if ('errors' in e && Array.isArray(e.errors)) {
      return accumulator.concat(e.errors.map((error) => error.message));
    }

    return accumulator;
  }, []);
};

const getGraphQLErrorMessages = (error: ApolloError): Array<string> => {
  const { message } = error;
  const isUndefined = message === undefined || message === 'undefined';
  const ignoreError = !isUndefined && message.includes('Error message not found');

  if (!isUndefined && !ignoreError) {
    return [message];
  }

  const errors: ReadonlyArray<GraphQLError | NestedGqlError> = error.graphQLErrors || [];

  return getErrorsFromArray(errors);
};

export default getGraphQLErrorMessages;
