import { print, type DocumentNode } from 'graphql';

import { APP__CLIENT_ENDPOINT } from '@/store/modules/app/getters';
import { getItemFromGetters, safeStringify } from '@/utils';

/**
 * @returns The token and endpoint from the vuex state
 */
export const getTokenEndpointFromState = () => {
  const token = getItemFromGetters<string>('auth/token');
  const endpoint = getItemFromGetters<string>(APP__CLIENT_ENDPOINT);

  return { token, endpoint };
};

export type QueryResult<T> = {
  data: T | null;
  error?: Error;
};

const genericError = {
  data: null,
  error: new Error('Unable to fetch data'),
};

/**
 * Executes a given graphql query using 'fetch' instead of using apollo.
 * Useful if you need to execute a query outside of a component or if you need to execute a query
 * in form validation asynchronously. Wherever possible use apollo instead.
 *
 * The first generic type is the type of the data returned by the query.
 * The second generic type is the type of the variables passed to the query (if there are any).
 *
 * @param query The graphql query to execute
 * @param variables The variables to pass to the query
 * @returns An object containing the data or an error
 */
const graphqlFetch = async <Q, V = Record<string, never>>(
  query: DocumentNode,
  variables?: V,
): Promise<QueryResult<Q>> => {
  const { token, endpoint } = getTokenEndpointFromState();

  if (!token || !endpoint) {
    return genericError;
  }

  const data = {
    query: print(query),
    variables: variables || {},
  };
  const payload = safeStringify(data);

  if (payload.isErr()) {
    return genericError;
  }

  const response = await fetch(endpoint, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: payload.value,
  });

  if (!response.ok) {
    return {
      data: null,
      error: new Error(response.statusText),
    };
  }

  try {
    const responseData: QueryResult<Q> = await response.json();

    return responseData;
  } catch (e: unknown) {
    return {
      data: null,
      error: new Error('Unable to parse response'),
    };
  }
};

export default graphqlFetch;
