import { useStore } from '@/store';
import {
  computed,
  ref,
  watch,
  type ComputedRef,
  type Ref,
} from 'vue';
import { useRoute, type RouteRecordName } from 'vue-router';

import { APP__SELECTED_PRODUCT } from '@/store/modules/app/getters';

import { getClientEndpointMap } from '@/apollo';
import type { ActiveProduct } from '@/config';
import {
  LOCAL_STORAGE_SELECTED_PRODUCT_KEY,
} from '@/config/constants';
import {
  getItemFromLocalStorage,
  isActiveProduct,
  removePortalSuffix,
} from '@/utils';

type ProductReturn = ActiveProduct | null;

type UseSelectedProductReturn = {
  isActiveProduct: (value: string) => boolean;
  getProductFromRoute: (name: RouteRecordName | null | undefined) => ProductReturn;
  productEndpoint: ComputedRef<string>;
  routeProduct: Ref<ProductReturn>;
  selectedPlatformProduct: ComputedRef<string | null>;
  selectedProduct: ComputedRef<ProductReturn>;
};

function getProductFromRoute(name: RouteRecordName | null | undefined): ProductReturn {
  if (!name || typeof name === 'symbol') {
    return null;
  }
  const [product] = name.split(':');

  return isActiveProduct(product) ? product : null;
}

function useSelectedProduct(): UseSelectedProductReturn {
  const { useGetter } = useStore();
  const route = useRoute();
  const routeProduct = ref(getProductFromRoute(route?.name));
  const apolloClientEndpoints = getClientEndpointMap();

  const routeName = computed(() => route && route.name);

  watch(() => routeName.value, (name) => {
    routeProduct.value = getProductFromRoute(name);
  });

  const selectedProduct = computed((): ProductReturn => {
    let storeProduct: ProductReturn = useGetter<ActiveProduct>(
      APP__SELECTED_PRODUCT,
    );

    if (!storeProduct) {
      const localStoreProduct = getItemFromLocalStorage<string>(
        LOCAL_STORAGE_SELECTED_PRODUCT_KEY,
      );

      if (localStoreProduct) {
        storeProduct = isActiveProduct(localStoreProduct) ? localStoreProduct : null;
      }
    }

    return storeProduct && isActiveProduct(storeProduct) ? storeProduct : null;
  });

  const selectedPlatformProduct = computed(() => (
    selectedProduct.value ? removePortalSuffix(selectedProduct.value) : null
  ));

  const productEndpoint = computed(() => (selectedProduct.value
    ? apolloClientEndpoints[selectedProduct.value]
    : ''
  ));

  return {
    getProductFromRoute,
    isActiveProduct,
    productEndpoint,
    routeProduct,
    selectedPlatformProduct,
    selectedProduct,
  };
}

export default useSelectedProduct;
