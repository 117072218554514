import { ok, err, type Result } from 'neverthrow';

/**
 * @param fn Function to call and wrap in try/catch
 * @returns Result<T, Error> - Result.Ok<T> if no error, Result.Err<Error> if error
 */
function wrapTryCatch<T, E extends Error>(fn: () => T): Result<T, Error> {
  try {
    return ok(fn());
  } catch (e) {
    return err(e as unknown as E);
  }
}

/**
 *
 * @param fn Function to call and wrap in try/catch
 * @returns Promise<Result<T, Error>> - Result.Ok<T> if no error, Result.Err<Error> if error
 */
function wrapTryCatchAsync<T, E extends Error>(fn: () => Promise<T>): Promise<Result<T, Error>> {
  return fn()
    .then((res) => ok(res))
    .catch((e) => err(e as unknown as E));
}

// TODO: add hof to send errors to error handler

export {
  wrapTryCatch,
  wrapTryCatchAsync,
};
