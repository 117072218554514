
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'EeaDefinitionIcon',
  props: {
    title: {
      type: String,
      required: false,
      default: 'EEA definition icon',
    },
  },
});
