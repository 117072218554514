import {
  ApolloClient,
} from '@apollo/client/core';
import type { NormalizedCacheObject } from '@apollo/client/core';

import createClient from './createClient';

export default function createDefaultClient(): ApolloClient<NormalizedCacheObject> | null {
  const DEFAULT_GRAPHQL_URI = process.env.VUE_APP_GRAPHQL_URI_DEFAULT;

  if (DEFAULT_GRAPHQL_URI) {
    return createClient({
      graphqlEndpoint: DEFAULT_GRAPHQL_URI,
    });
  }

  return null;
}
