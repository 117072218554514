import type { RouteRecordRaw } from 'vue-router';

import {
  AuditHistoryIcon,
  DashboardIcon,
  DataExportIcon,
  DataImportIcon,
  EeaDefinitionIcon,
  GlobalDefinitionIcon,
  JurisdictionManagementIcon,
  LegendIcon,
  LocalDefinitionIcon,
  ManageUpdateCycleIcon,
  StatusIcon,
  SupportingDocsIcon,
} from '@/assets/icons/';

import { getProductPermissions } from '@/config';
import { PortalProducts } from '@/config/constants';
import { Paths, Views } from '@/router/router.config';

const {
  ownerPermissions,
  collaboratorPermissions,
} = getProductPermissions(PortalProducts.NAVIGATOR);

const navigatorRoutes: Array<RouteRecordRaw> = [
  {
    path: `/${Paths.NAVIGATOR}`,
    name: PortalProducts.NAVIGATOR,
    component: () => import(
      /* webpackChunkName: "navigator" */ '@/products/navigator/NavigatorLayout.vue'
    ),
    redirect: () => ({ name: `${PortalProducts.NAVIGATOR}:${Views.DASHBOARD}` }),
    meta: {
      requiredPermissions: [...collaboratorPermissions],
    },
    children: [
      {
        path: Paths.DASHBOARD,
        name: `${PortalProducts.NAVIGATOR}:${Views.DASHBOARD}`,
        component: () => import(
          /* webpackChunkName: "navigator-dashboard-view" */ '@/products/navigator/NavigatorDashboard.vue'
        ),
        meta: {
          requiredPermissions: [...collaboratorPermissions],
          icon: DashboardIcon,
        },
      },
      {
        path: `${Paths.DOCUMENT}/:documentCycleId`,
        name: `${PortalProducts.NAVIGATOR}:${Views.DOCUMENT}`,
        component: () => import(/* webpackChunkName: "document-view" */ '@/views/document/DocumentView.vue'),
        meta: {
          requiredPermissions: [...collaboratorPermissions],
        },
        props: (route) => ({ documentCycleId: route.params.documentCycleId }),
      },
      {
        path: `${Paths.DOCUMENT}/:documentCycleId/${Paths.VERSION}/:versionId`,
        name: `${PortalProducts.NAVIGATOR}:${Views.DOCUMENT_VERSION}`,
        component: () => import(/* webpackChunkName: "document-view" */ '@/views/document/DocumentView.vue'),
        meta: {
          requiredPermissions: [...collaboratorPermissions],
        },
        props: (route) => ({
          documentCycleId: route.params.documentCycleId,
          versionId: route.params.versionId,
        }),
      },
      {
        path: Paths.UPDATE_CYCLE,
        name: `${PortalProducts.NAVIGATOR}:${Views.UPDATE_CYCLE}`,
        redirect: () => ({ name: `${PortalProducts.NAVIGATOR}:${Views.UPDATE_CYCLE_MANAGE}` }),
        component: () => import(/* webpackChunkName: "update-cycle-view" */'@/views/update-cycle'),
        meta: {
          requiredPermissions: [...ownerPermissions],
        },
        children: [
          {
            path: Paths.MANAGE,
            name: `${PortalProducts.NAVIGATOR}:${Views.UPDATE_CYCLE_MANAGE}`,
            component: () => import(/* webpackChunkName: "manage-update-cycle-view" */'@/views/update-cycle/pages/manage'),
            meta: {
              requiredPermissions: [...ownerPermissions],
              icon: ManageUpdateCycleIcon,
            },
          },
          {
            path: `${Paths.PUBLISH}/:cycleId`,
            name: `${PortalProducts.NAVIGATOR}:${Views.UPDATE_CYCLE_PUBLISH}`,
            component: () => import(/* webpackChunkName: "publish-update-cycle-view" */ '@/views/update-cycle/pages/publish'),
            meta: {
              requiredPermissions: [...ownerPermissions],
            },
          },
          {
            path: Paths.CREATE,
            name: `${PortalProducts.NAVIGATOR}:${Views.UPDATE_CYCLE_CREATE}`,
            component: () => import(/* webpackChunkName: "create-update-cycle-view" */'@/views/update-cycle/pages/create'),
            meta: {
              requiredPermissions: [...ownerPermissions],
            },
          },
        ],
      },
      {
        path: Paths.LOCAL_DEFINITIONS,
        name: `${PortalProducts.NAVIGATOR}:${Views.LOCAL_DEFINITIONS}`,
        redirect: () => ({ name: `${PortalProducts.NAVIGATOR}:${Views.LOCAL_DEFINITIONS_LIST}` }),
        component: () => import(/* webpackChunkName: "local-definitions-view" */'@/views/local-definitions/LocalDefinitions.vue'),
        meta: {
          requiredPermissions: [...ownerPermissions],
          icon: LocalDefinitionIcon,
        },
        children: [
          {
            path: Paths.LIST,
            name: `${PortalProducts.NAVIGATOR}:${Views.LOCAL_DEFINITIONS_LIST}`,
            component: () => import(/* webpackChunkName: "list-local-definitions-view" */'@/views/local-definitions/ListLocalDefinitions.vue'),
            meta: {
              requiredPermissions: [...ownerPermissions],
            },
          },
          {
            path: Paths.CREATE,
            name: `${PortalProducts.NAVIGATOR}:${Views.LOCAL_DEFINITIONS_CREATE}`,
            component: () => import(/* webpackChunkName: "create-local-definitions-view" */'@/views/local-definitions/CreateLocalDefinition.vue'),
            meta: {
              requiredPermissions: [...ownerPermissions],
            },
          },
        ],
      },
      {
        path: Paths.GLOBAL_DEFINITIONS,
        name: `${PortalProducts.NAVIGATOR}:${Views.GLOBAL_DEFINITIONS}`,
        redirect: () => ({ name: `${PortalProducts.NAVIGATOR}:${Views.GLOBAL_DEFINITIONS_LIST}` }),
        component: () => import(/* webpackChunkName: "navigator-global-definitions-view" */'@/views/global-definitions/GlobalDefinitions.vue'),
        meta: {
          requiredPermissions: [...ownerPermissions],
          icon: GlobalDefinitionIcon,
        },
        children: [
          {
            path: Paths.LIST,
            name: `${PortalProducts.NAVIGATOR}:${Views.GLOBAL_DEFINITIONS_LIST}`,
            component: () => import(/* webpackChunkName: "navigator-list-global-definitions-view" */'@/views/global-definitions/pages/list'),
            meta: {
              requiredPermissions: [...ownerPermissions],
            },
          },
          {
            path: Paths.CREATE,
            name: `${PortalProducts.NAVIGATOR}:${Views.GLOBAL_DEFINITIONS_CREATE}`,
            component: () => import(/* webpackChunkName: "navigator-create-global-definitions-view" */'@/views/global-definitions/pages/create'),
            meta: {
              requiredPermissions: [...ownerPermissions],
            },
          },
          {
            path: `${Paths.EDIT}/:definitionId`,
            name: `${PortalProducts.NAVIGATOR}:${Views.GLOBAL_DEFINITIONS_EDIT}`,
            component: () => import(/* webpackChunkName: "navigator-edit-global-definitions-view" */'@/views/global-definitions/pages/edit'),
            meta: {
              requiredPermissions: ['navigator-collaboration-owner'],
            },
          },
        ],
      },
      {
        path: Paths.EEA_DEFINITIONS,
        name: `${PortalProducts.NAVIGATOR}:${Views.EEA_DEFINITIONS}`,
        redirect: () => ({ name: `${PortalProducts.NAVIGATOR}:${Views.EEA_DEFINITIONS_LIST}` }),
        component: () => import(/* webpackChunkName: "eea-definitions-view" */'@/views/eea-definitions/EEADefinitions.vue'),
        meta: {
          requiredPermissions: ['navigator-collaboration-owner'],
          icon: EeaDefinitionIcon,
        },
        children: [
          {
            path: Paths.LIST,
            name: `${PortalProducts.NAVIGATOR}:${Views.EEA_DEFINITIONS_LIST}`,
            component: () => import(/* webpackChunkName: "list-eea-definitions-view" */'@/views/eea-definitions/pages/list/ListEEADefinitions.vue'),
            meta: {
              requiredPermissions: ['navigator-collaboration-owner'],
            },
          },
          {
            path: Paths.CREATE,
            name: `${PortalProducts.NAVIGATOR}:${Views.EEA_DEFINITIONS_CREATE}`,
            component: () => import(/* webpackChunkName: "create-eea-definitions-view" */'@/views/eea-definitions/pages/create/CreateEEADefinition.vue'),
            meta: {
              requiredPermissions: ['navigator-collaboration-owner'],
            },
          },
          {
            path: `${Paths.EDIT}/:definitionId`,
            name: `${PortalProducts.NAVIGATOR}:${Views.EEA_DEFINITIONS_EDIT}`,
            component: () => import(/* webpackChunkName: "edit-eea-definitions-view" */'@/views/eea-definitions/pages/edit/EditEEADefinition.vue'),
            meta: {
              requiredPermissions: [...ownerPermissions],
            },
          },
        ],
      },
      {
        path: Paths.LEGENDS,
        name: `${PortalProducts.NAVIGATOR}:${Views.LEGENDS}`,
        redirect: () => ({ name: `${PortalProducts.NAVIGATOR}:${Views.LEGENDS_LIST}` }),
        component: () => import(/* webpackChunkName: "navigator-legends-view" */'../views/legends'),
        meta: {
          requiredPermissions: [...ownerPermissions],
          icon: LegendIcon,
        },
        children: [
          {
            path: Paths.LIST,
            name: `${PortalProducts.NAVIGATOR}:${Views.LEGENDS_LIST}`,
            component: () => import(/* webpackChunkName: "navigator-list-legends-view" */'../views/legends/pages/list'),
            meta: {
              requiredPermissions: [...ownerPermissions],
            },
          },
          {
            path: Paths.CREATE,
            name: `${PortalProducts.NAVIGATOR}:${Views.LEGENDS_CREATE}`,
            component: () => import(/* webpackChunkName: "navigator-create-legend-view" */'../views/legends/pages/create'),
            meta: {
              requiredPermissions: [...ownerPermissions],
            },
          },
        ],
      },
      {
        path: Paths.STATUS_TABLE,
        name: `${PortalProducts.NAVIGATOR}:${Views.STATUS_TABLE}`,
        component: () => import(/* webpackChunkName: "status-table-view" */'@/views/status/StatusTable.vue'),
        meta: {
          requiredPermissions: [...ownerPermissions],
          icon: StatusIcon,
        },
        redirect: {
          name: `${PortalProducts.NAVIGATOR}:${Views.STATUS_TABLE_MANAGE}`,
        },
        children: [
          {
            path: Paths.MANAGE,
            name: `${PortalProducts.NAVIGATOR}:${Views.STATUS_TABLE_MANAGE}`,
            // eslint-disable-next-line import/no-unresolved
            component: () => import(/* webpackChunkName: "manage-status-table-view" */'@/views/manage-status/ManageStatusTable.vue'),
            meta: {
              requiredPermissions: [...ownerPermissions],
            },
          },
          {
            path: `${Paths.EDIT}/:statusId`,
            name: `${PortalProducts.NAVIGATOR}:${Views.STATUS_TABLE_EDIT}`,
            component: () => import(/* webpackChunkName: "edit-status-entry-view" */'@/products/navigator/views/status/EditStatusEntry.vue'),
            meta: {
              requiredPermissions: [...ownerPermissions],
            },
            props: (route) => ({
              statusId: route.params.statusId,
            }),
          },
          {
            path: Paths.CREATE,
            name: `${PortalProducts.NAVIGATOR}:${Views.STATUS_TABLE_CREATE}`,
            component: () => import(/* webpackChunkName: "create-status-entry-view" */'@/products/navigator/views/status/CreateStatusEntry.vue'),
            meta: {
              requiredPermissions: [...ownerPermissions],
            },
          },
        ],
      },
      {
        path: Paths.JURISDICTION_MANAGEMENT,
        name: `${PortalProducts.NAVIGATOR}:${Views.JURISDICTION_MANAGEMENT}`,
        redirect: () => ({ name: `${PortalProducts.NAVIGATOR}:${Views.JURISDICTIONS_LIST}` }),
        component: () => import(/* webpackChunkName: "jurisdiction-management-view" */ '@/views/jurisdiction-management/JurisdictionManagement.vue'),
        meta: {
          requiredPermissions: [...ownerPermissions],
          icon: JurisdictionManagementIcon,
        },
        children: [
          {
            path: Paths.LIST,
            name: `${PortalProducts.NAVIGATOR}:${Views.JURISDICTIONS_LIST}`,
            component: () => import(/* webpackChunkName: "list-jurisdictions-view" */'@/views/jurisdiction-management/ListJurisdictions.vue'),
            meta: {
              requiredPermissions: [...ownerPermissions],
            },
          },
          {
            path: Paths.CREATE,
            name: `${PortalProducts.NAVIGATOR}:${Views.JURISDICTIONS_CREATE}`,
            component: () => import(/* webpackChunkName: "create-jurisdiction-form" */'@/views/jurisdiction-management/CreateJurisdiction.vue'),
            meta: {
              requiredPermissions: [...ownerPermissions],
            },
          },
        ],
      },
      {
        path: Paths.SUPPORTING_DOCS,
        name: `${PortalProducts.NAVIGATOR}:${Views.SUPPORTING_DOCS}`,
        redirect: () => ({ name: `${PortalProducts.NAVIGATOR}:${Views.SUPPORTING_DOCS_LIST}` }),
        component: () => import(/* webpackChunkName: "supporting-docs-view" */'@/products/navigator/views/supporting-docs/SupportingDocs.vue'),
        meta: {
          requiredPermissions: [...ownerPermissions],
          icon: SupportingDocsIcon,
        },
        children: [
          {
            path: Paths.LIST,
            name: `${PortalProducts.NAVIGATOR}:${Views.SUPPORTING_DOCS_LIST}`,
            component: () => import(/* webpackChunkName: "list-supporting-docs-view" */'@/products/navigator/views/supporting-docs/ListSupportingDocs.vue'),
            meta: {
              requiredPermissions: [...ownerPermissions],
            },
          },
          {
            path: Paths.CREATE,
            name: `${PortalProducts.NAVIGATOR}:${Views.SUPPORTING_DOCS_CREATE}`,
            component: () => import(/* webpackChunkName: "create-supporting-documents-view" */'@/products/navigator/views/supporting-docs/CreateSupportingDoc.vue'),
            meta: {
              requiredPermissions: [...ownerPermissions],
            },
          },
          {
            path: `${Paths.EDIT}/:supportingDocId`,
            name: `${PortalProducts.NAVIGATOR}:${Views.SUPPORTING_DOCS_EDIT}`,
            component: () => import(/* webpackChunkName: "edit-supporting-documents-view" */'@/products/navigator/views/supporting-docs/EditSupportingDoc.vue'),
            meta: {
              requiredPermissions: [...ownerPermissions],
            },
          },
        ],
      },
      {
        path: Paths.AUDIT_HISTORY,
        name: `${PortalProducts.NAVIGATOR}:${Views.AUDIT_HISTORY}`,
        component: () => import(/* webpackChunkName: "audit-hitory-view" */ '@/views/audit-history/AuditHistory.vue'),
        meta: {
          requiredPermissions: [...ownerPermissions],
          icon: AuditHistoryIcon,
        },
      },
      {
        path: Paths.DATA_EXPORT,
        name: `${PortalProducts.NAVIGATOR}:${Views.DATA_EXPORT}`,
        component: () => import(/* webpackChunkName: "audit-history-view" */'@/views/data-export/DataExport.vue'),
        meta: {
          requiredPermissions: [...ownerPermissions],
          icon: DataExportIcon,
        },
      },
      {
        path: Paths.DATA_IMPORT,
        name: `${PortalProducts.NAVIGATOR}:${Views.DATA_IMPORT}`,
        component: () => import(/* webpackChunkName: "data-import-view" */'@/views/data-import/DataImport.vue'),
        redirect: () => ({ name: `${PortalProducts.NAVIGATOR}:${Views.DATA_IMPORT_LIST}` }),
        meta: {
          requiredPermissions: [...ownerPermissions],
          icon: DataImportIcon,
        },
        children: [
          {
            path: Paths.DATA_IMPORT_LIST,
            name: `${PortalProducts.NAVIGATOR}:${Views.DATA_IMPORT_LIST}`,
            component: () => import(/* webpackChunkName: "list-data-import-view" */'@/views/data-import/ListDataImport.vue'),
            meta: {
              requiredPermissions: [...ownerPermissions],
            },
          },
          {
            path: `${Paths.DATA_IMPORT_PUBLISH}/:id`,
            name: `${PortalProducts.NAVIGATOR}:${Views.DATA_IMPORT_PUBLISH}`,
            component: () => import(/* webpackChunkName: "publish-file-import-view" */ '@/views/data-import/publish'),
            meta: {
              requiredPermissions: [...ownerPermissions],
            },
          },
          {
            path: `${Paths.FILE_IMPORT_VALIDATION_REPORT}/:id`,
            name: `${PortalProducts.NAVIGATOR}:${Views.FILE_IMPORT_VALIDATION_REPORT}`,
            component: () => import(/* webpackChunkName: "file-import-report-view" */ '@/views/data-import/components/ValidationReport'),
            meta: {
              requiredPermissions: [...ownerPermissions],
            },
          },
          {
            path: `${Paths.FILE_IMPORT_PREAUDIT_REPORT}/:id`,
            name: `${PortalProducts.NAVIGATOR}:${Views.FILE_IMPORT_PREAUDIT_REPORT}`,
            component: () => import(/* webpackChunkName: "file-import-report-view" */ '@/views/data-import/components/AuditReports'),
            meta: {
              requiredPermissions: [...ownerPermissions],
              auditType: 'Pre-Audit',
            },
          },
          {
            path: `${Paths.FILE_IMPORT_POSTAUDIT_REPORT}/:id`,
            name: `${PortalProducts.NAVIGATOR}:${Views.FILE_IMPORT_POSTAUDIT_REPORT}`,
            component: () => import(/* webpackChunkName: "file-import-report-view" */ '@/views/data-import/components/AuditReports'),
            meta: {
              requiredPermissions: [...ownerPermissions],
              auditType: 'Post-Audit',
            },
          },
          {
            path: Paths.DATA_IMPORT_CREATE,
            name: `${PortalProducts.NAVIGATOR}:${Views.DATA_IMPORT_CREATE}`,
            component: () => import(/* webpackChunkName: "new-data-import-view" */'@/views/data-import/NewDataImport.vue'),
            meta: {
              requiredPermissions: [...ownerPermissions],
            },
          },
        ],
      },
    ],
  },
];

export default navigatorRoutes;
