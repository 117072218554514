import {
  computed,
  unref,
  watchEffect,
  type Ref,
} from 'vue';

import useMutatingIndicators from './useMutatingIndicators';
import type { MutatingIndicatorType } from './useMutatingIndicators.types';

function useMutatingIndicator(
  type: MutatingIndicatorType | Ref<MutatingIndicatorType>,
  id: string | Ref<string>,
  updatedAt: Date | Ref<Date>,
) {
  const store = useMutatingIndicators(type);

  const theId = computed(() => unref(id));
  const theUpdatedAt = computed(() => unref(updatedAt));
  const isMutating = computed(() => store.isMutating(theId.value, theUpdatedAt.value));
  const hasError = computed(() => store.hasError(theId.value, theUpdatedAt.value));

  watchEffect(() => {
    if (!isMutating.value && !hasError.value) {
      store.remove(theId.value);
    }
  });

  return {
    isMutating,
    hasError,
  };
}

export default useMutatingIndicator;
