import { onUnmounted } from 'vue';

import { listenForLocalStorageChanges, setItemInLocalStorage } from '@/utils/localStorage';
import { safeParse, isActiveProduct } from '@/utils';
import { LOCAL_STORAGE_SELECTED_PRODUCT_KEY, ActiveProducts } from '@/config/constants';

function listenForSelectedProductChanges() {
  const setFirstValidProduct = () => {
    if (ActiveProducts.length > 0) {
      setItemInLocalStorage(LOCAL_STORAGE_SELECTED_PRODUCT_KEY, ActiveProducts[0]);
    }
  };

  const fn = (payload: string | null) => {
    if (!payload) {
      setFirstValidProduct();

      return;
    }
    const payloadResult = safeParse<string>(payload).unwrapOr(null);

    // check to see if the selected product is valid
    // if not, set the selected product to the first valid product
    if (!payloadResult || !isActiveProduct(payloadResult)) {
      setFirstValidProduct();
    }
  };

  return listenForLocalStorageChanges(LOCAL_STORAGE_SELECTED_PRODUCT_KEY, fn);
}

function useAppEventListeners() {
  const unsubscribe = listenForSelectedProductChanges();

  onUnmounted(unsubscribe);
}

export default useAppEventListeners;
