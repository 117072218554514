import { get } from 'lodash-es';
import type { Truthy, ExcludeNullish, LabelValue } from '@/types/utilityTypes';

export function truthy<T>(value: T): value is Truthy<T> {
  return !!value;
}

export function nonNullable<T>(value: T): value is NonNullable<T> {
  return value !== null && value !== undefined;
}

export const excludeNullish: ExcludeNullish = (x): x is Exclude<
  typeof x,
  null | undefined
> => x != null;

export const mapToLabelValue = <T>(
  data: T[],
  mapping: LabelValue,
): LabelValue[] => (data.filter(excludeNullish).map(
    (entry): LabelValue => ({
      value: get(entry, mapping.value, '') as string,
      label: get(entry, mapping.label, '') as string,
    }),
  )
    .filter(({ value }) => value)
  );
