import type {
  CommitOptions,
  Module,
  Store as VuexStore,
} from 'vuex';

import { getSavedLocalStorage } from '@/store/modules/localStorage/utils';

import { getters } from './getters';
import { mutations } from './mutations';

import type { Getters } from './getters';
import type { Mutations } from './mutations';
import type { RootState } from '@/store/state';
import type { State } from './state';

const state: State = getSavedLocalStorage();

export type Store<S = State> = Omit<
  VuexStore<S>,
  'commit' | 'getters' | 'dispatch'
> & {
  commit<K extends keyof Mutations, P extends Parameters<Mutations[K]>[1]>(
    key: K,
    payload: P,
    options?: CommitOptions,
  ): ReturnType<Mutations[K]>;
} & {
  getters: {
    [K in keyof Getters]: ReturnType<Getters[K]>
  };
};

export const LocalStorageStore: Module<State, RootState> = {
  state,
  mutations,
  getters,
};
