import StatusesQuery from '@/shared/graphql/query/PaginatedStatuses.gql';
import type {
  StatusesQuery as SharedStatusesQuery,
  StatusesQueryVariables as SharedStatusesQueryVariables,
} from '@/generated/shared-graphql';

import useProductMutation from '@/composables/useProductMutation';

// We are using `useProductMutation` here instead of `useLazyQuery` to bypass
// caching system and not to call `load` and `refetch` functions separately
// also there are issues when `onResult` wasn't called on second call of `refetch`
// if `result` is the same.
const useLazyExportStatuses = () => {
  const statusMutation = useProductMutation<
    SharedStatusesQuery,
    SharedStatusesQueryVariables
  >(StatusesQuery);
  const fetchStatuses = (
    variables: SharedStatusesQueryVariables,
  ) => statusMutation.mutate(variables, {
    errorPolicy: 'all',
    fetchPolicy: 'network-only',
  });

  return {
    fetchStatuses,
  };
};

export default useLazyExportStatuses;
