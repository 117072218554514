import { ApolloClient, type NormalizedCacheObject } from '@apollo/client/core';
import { utilities, type Endpoints } from '@simmons/product-platform-config';

import createClient from './createClient';
import createDefaultClient from './defaultClient';

import { getValidActiveProducts, type ActiveProduct } from '@/config';
import { ActiveProducts } from '@/config/constants';
import { getEnvironment } from '@/utils/envVars';

export type ActiveProductEndpoint = {
  [k in ActiveProduct]: string;
};

export type ApolloClients = {
  [k in ActiveProduct | 'default']: ApolloClient<NormalizedCacheObject> | null;
};

const clients = ((): ApolloClients => {
  const productClients = ActiveProducts.map((product) => ({
    [product]: null,
  }));
  const defaultClient = {
    default: null,
  };

  return Object.assign({}, ...productClients, defaultClient);
})();

const clientEndpointMap = ((): ActiveProductEndpoint => {
  const productEndpoints = ActiveProducts.map((product) => ({
    [product]: '',
  }));

  return Object.assign({}, ...productEndpoints);
})();

export function getClientEndpointMap() {
  return Object.freeze(clientEndpointMap);
}

export function getAllClients(): Readonly<ApolloClients> {
  if (Object.entries(clients).every(([, client]) => client !== null)) {
    return clients;
  }

  const {
    getConfigForProduct,
    getBranchStackIdFromProductForEnvironment,
  } = utilities;

  const products = getValidActiveProducts();
  const envName = getEnvironment();
  const defaultClient = createDefaultClient();

  if (defaultClient) {
    clients.default = defaultClient;
  }

  products.forEach((product): void => {
    let endpoints: Endpoints | null = null;
    let branchStackId: string | null = null;
    let graphqlEndpoint = '';

    try {
      endpoints = getConfigForProduct<Endpoints>('endpoints', product);
    } catch (err) {
      console.error((err as Error).message);
    }

    try {
      branchStackId = getBranchStackIdFromProductForEnvironment(product, envName);
    } catch (err) {
      console.error((err as Error).message);
    }

    const frontEndConfig = (
      branchStackId
      && endpoints?.publish?.[branchStackId]
    );

    if (frontEndConfig) {
      graphqlEndpoint = frontEndConfig?.graphql;
    }

    if (graphqlEndpoint) {
      clients[product] = createClient({
        graphqlEndpoint,
      });
      clientEndpointMap[product] = graphqlEndpoint;
    }
  });

  return Object.freeze(clients);
}

export function getClient(
  product: ActiveProduct | 'default',
): ApolloClient<NormalizedCacheObject> | null {
  return clients[product];
}
