import type {
  MutatingIndicatorType,
} from './useMutatingIndicators.types';

const DEFAULT_TIMEOUT = 300;

const getParsedEnv = (value: string | number): number => {
  const result = typeof value === 'string'
    ? parseInt(value, 10)
    : value;

  if (Number.isNaN(result)) {
    return DEFAULT_TIMEOUT;
  }

  return result;
};

const envTypes: Record<MutatingIndicatorType, number | string> = {
  'eea-definitions': process.env.VUE_APP_MUTATING_EAA_DEFINITION_INDICATOR_TIMEOUT ?? DEFAULT_TIMEOUT,
  'global-definitions': process.env.VUE_APP_MUTATING_GLOBAL_DEFINITION_INDICATOR_TIMEOUT ?? DEFAULT_TIMEOUT,
  'local-definitions': process.env.VUE_APP_MUTATING_LOCAL_DEFINITION_INDICATOR_TIMEOUT ?? DEFAULT_TIMEOUT,
  'jurisdiction-management-eea': process.env.VUE_APP_MUTATING_JURISDICTION_EAA_INDICATOR_TIMEOUT ?? DEFAULT_TIMEOUT,
  'supporting-documents': process.env.VUE_APP_MUTATING_SUPPORTING_DOCUMENTS_INDICATOR_TIMEOUT ?? DEFAULT_TIMEOUT,
  status: process.env.VUE_APP_MUTATING_STATUS_INDICATOR_TIMEOUT ?? DEFAULT_TIMEOUT,
  legends: process.env.VUE_APP_MUTATING_LEGEND_INDICATOR_TIMEOUT ?? DEFAULT_TIMEOUT,
};

// Returned value is in seconds, but not in milliseconds
const getIndicatorTimeout = (type: MutatingIndicatorType): number => {
  const value = envTypes[type];

  return getParsedEnv(value);
};

export default getIndicatorTimeout;
