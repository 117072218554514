import { GetterTree } from 'vuex';

import { State } from '@/store/modules/localStorage/state';
import { RootState } from '@/store/state';

export enum LocalStorageGetters {
  LOCAL_STORAGE_GET = 'LOCAL_STORAGE_GET',
}

export type Getters = {
  [LocalStorageGetters.LOCAL_STORAGE_GET](state: State): (key: string) => string | null;
}

export const getters: GetterTree<State, RootState> & Getters = {
  [LocalStorageGetters.LOCAL_STORAGE_GET]: (state) => (key) => state[key],
};
