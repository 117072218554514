import CreateLocationMutationQuery from '@/shared/graphql/mutation/CreateLocation.gql';
import type {
  CreateLocationMutation as SharedCreateLocationMutationQueryType,
  CreateLocationMutationVariables as SharedCreateLocationMutationVariables,
  LocationFieldsCreateWithoutLocationInput as SharedLocationFieldsCreateWithoutLocationInput,
} from '@/generated/shared-graphql';

import type {
  HeadwindsCreateLocationMutation as HeadwindsCreateLocationMutationQueryType,
  HeadwindsCreateLocationMutationVariables,
  LocationFieldsCreateWithoutLocationInput as HeadwindsLocationFieldsCreateWithoutLocationInput,
} from '@/generated/headwinds-portal-graphql';

import useProductMutation from './useProductMutation';

type CreateLocationMutationQueryType = HeadwindsCreateLocationMutationQueryType |
SharedCreateLocationMutationQueryType;
type CreateLocationMutationVariables = HeadwindsCreateLocationMutationVariables |
SharedCreateLocationMutationVariables;
type LocationFieldsCreateWithoutLocationInput = HeadwindsLocationFieldsCreateWithoutLocationInput |
SharedLocationFieldsCreateWithoutLocationInput;

const useCreateLocationFields = () => {
  const createLocationReturn = useProductMutation<
    CreateLocationMutationQueryType,
    CreateLocationMutationVariables
  >(CreateLocationMutationQuery);

  const mutate = (
    values:
      LocationFieldsCreateWithoutLocationInput[],
  ) => {
    const variables = {
      data: {
        published: false,
        reference: '',
        fields: {
          create: values,
        },
      },
    };

    return createLocationReturn.mutate(variables);
  };

  return {
    ...createLocationReturn,
    mutate,
  };
};

export default useCreateLocationFields;
