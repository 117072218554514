import { RootState } from '@/store/state';
import { ActionContext, ActionTree } from 'vuex';
import { Mutations } from './mutations';
import { State } from './state';

// Action enums
export enum AppActions {
  APP__EXAMPLE = 'APP__EXAMPLE',
}

// Actions context
type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1],
  ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, 'commit'>;

// Actions contracts
export interface Actions {
  [AppActions.APP__EXAMPLE](
    { commit }: AugmentedActionContext,
    payload: { test: true; },
  ): void;
}

// Define actions
export const actions: ActionTree<State, RootState> & Actions = {
  async [AppActions.APP__EXAMPLE](
    // { commit },
    // payload: { test: boolean; },
  ) {
    try {
      // console.log('Example action triggered', { commit, payload });
      // TODO: something
    } catch (err) {
      // console.error('Error');
      // TODO: error
    }
  },
};
