import { ok, err, type Result } from 'neverthrow';

/**
 * @param name The name of the environment variable to get
 * @returns The value of the environment variable, or an error if it is not set
 */
export function getEnvVar(envVarName: string): Result<string, Error> {
  const value = process.env[envVarName];

  if (!value) {
    return err(Error(`Environment variable '${envVarName}' is not set`));
  }

  return ok(value);
}

/**
 * @param envVarName The name of the environment variable to get
 * @returns The value of the environment variable wrapped in an object keyed by the environment
 * variable name, or an error if it is not set
 */
export function getEnvVarKeyed(envVarName: string): Result<Record<string, string>, Error> {
  const envValue = getEnvVar(envVarName);

  if (envValue.isErr()) {
    return err(envValue.error);
  }

  return ok({
    [envVarName]: envValue.value,
  });
}

/**
 * @returns The value of the environment variable (in lowercase),
 * or an empty string if it is not set.
 */
export function getEnvironment() {
  const environment = getEnvVar('VUE_APP_ENVIRONMENT_NAME').map((env) => env.toLowerCase());

  if (environment.isErr()) {
    return '';
  }

  return environment.value;
}

/**
 * @returns An array of active products or an empty array if the environment variable is not set
 */
export function getActiveProducts() {
  const activeProducts = getEnvVar('VUE_APP_ACTIVE_PRODUCTS');

  if (activeProducts.isErr()) {
    return [];
  }

  const splitChar = activeProducts.value.includes('|') ? '|' : ',';

  return activeProducts.value.split(splitChar).map((product: string) => product.trim());
}

/**
 * @param url The URL string to check
 * @returns The URL string with a trailing slash
 */
export function getUrlWithTrailingSlash(url: string): string {
  return url.endsWith('/') ? url : `${url}/`;
}
