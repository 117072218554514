/* eslint-disable no-console */
import { register as registerServiceWorker } from 'register-service-worker';
import { getUrlWithTrailingSlash, getEnvVar } from '@/utils/envVars';

class Worker {
  private isDownloadingUpdates = false;

  private hasPendingUpdates = false;

  private notificationsAllowed = false;

  private registration: ServiceWorkerRegistration | null = null;

  private environmentsToRegisterIn: string[] = [
    'production',
    'qa',
    'staging',
    'test',
  ];

  async checkForUpdates() {
    await this.registration?.update();
  }

  /**
   * Ask user permission to receive notifications
   *
   * @returns Promise<boolean> true if permission granted, false otherwise
   */
  askPermission(): Promise<boolean> {
    if (!Reflect.has(window, 'Notification')) {
      return Promise.resolve(false);
    }

    return new Promise((resolve, reject) => Notification.requestPermission().then(
      (permissionResult) => {
        if (permissionResult !== 'granted') {
          this.notificationsAllowed = false;
          reject(Error('Notification permission denied'));
        }

        this.notificationsAllowed = true;
        resolve(true);
      },
    ));
  }

  /**
   * @param title Notification title
   * @param options Notification options
   * @returns void
   */
  notify(title: string, options: NotificationOptions = {}) {
    if (!this.notificationsAllowed || !this.registration) {
      return;
    }

    this.registration.showNotification(title, options);
  }

  reloadIfHasUpdates() {
    if (this.hasPendingUpdates) {
      window.location.reload();
    }
  }

  skipWaiting() {
    this.registration?.waiting?.postMessage({ action: 'skipWaiting' });
  }

  register() {
    const envBaseUrl = getEnvVar('BASE_URL').unwrapOr('');
    const envNodeEnv = getEnvVar('NODE_ENV').unwrapOr('');
    const baseUrl = getUrlWithTrailingSlash(envBaseUrl);

    if (
      this.environmentsToRegisterIn.includes(envNodeEnv) && baseUrl
    ) {
      registerServiceWorker(`${baseUrl}service-worker.js`, {
        ready: () => {
          this.hasPendingUpdates = false;
        },
        registered: (_registration) => {
          this.registration = _registration;
        },
        updatefound: () => {
          this.isDownloadingUpdates = true;
        },
        updated: (_registration) => {
          this.registration = _registration;
          this.skipWaiting();

          // Need to change the flag only when update comes in an active user session
          // but not when user just came to the site.
          //
          // In case when user just entered and service-worker was updated while he has offline
          // then just `updated` will be called.
          //
          // In case when update arrived when user is using application then
          // `updatefound` and then `updated` will be called
          if (this.isDownloadingUpdates) {
            this.isDownloadingUpdates = false;
            this.hasPendingUpdates = true;
          }

          this.reloadIfHasUpdates();
        },
      });
    }
  }
}

const worker = new Worker();

export default worker;
export type { Worker };
