import { App } from 'vue';
import { RouteLocationNamedRaw, Router } from 'vue-router';

import {
  AuthErrors,
  LOCAL_STORAGE_SELECTED_PRODUCT_KEY,
  PortalProducts,
} from '@/config/constants';
import { Paths, Views } from '@/router/router.config';

import { getStore } from '@/store';
import { APP__SELECTED_PRODUCT } from '@/store/modules/app/getters';
import { APP__SET_SELECTED_PRODUCT } from '@/store/modules/app/mutations';
import type { AuthActions, AuthGetters } from '@/store/modules/auth/auth.types';

import {
  getRouteProductName,
  initialSelectedProduct,
} from '@/utils';
import { getItemFromLocalStorage, setItemInLocalStorage } from '@/utils/localStorage';
import { getItemFromGetters, setItemInState } from '@/utils/vuexState';
import { toPortalProduct } from '../utils/convertPortalProduct';
import { logError } from '../utils/log';

function toAuthPage(options: RouteLocationNamedRaw = {}) {
  return {
    name: Views.AUTHENTICATION,
    ...options,
  };
}

function collabPermissionsToProducts(permissions: string[]): string[] {
  if (!permissions || !permissions.length) return [];

  const productAccess: string[] = [];

  permissions.forEach((permission) => {
    const splitPermission = permission.split('|');
    // take the second part of the split permission
    const [, productPermission] = splitPermission;
    const [product] = productPermission.split('-collaboration-');
    const productTrimmed = product.trim();

    if (!productAccess.includes(productTrimmed)) {
      productAccess.push(productTrimmed);
    }
  });

  return productAccess;
}

function toHomePage(userCollabPermissions: string[]) {
  // when this function is called, the user is authenticated so we should have access to
  // the user's collaboration permissions
  const productAccess = collabPermissionsToProducts(userCollabPermissions);
  const selectedProduct = initialSelectedProduct(productAccess);

  return selectedProduct
    ? {
      name: selectedProduct,
    }
    : toAuthPage();
}

async function renewSession(renewFn: () => Promise<boolean>) {
  let renewed = false;

  try {
    renewed = await renewFn();
  } catch {
    renewed = false;
  }

  return renewed;
}

/**
 * Update the page title on each route change and check then
 * authentication status of the user
 * @param router Vue Router instance
 */
function setupAuthentication(router: Router) {
  const store = getStore();
  const renewFn = () => store.dispatch(
    'auth/renewSession',
  ) as ReturnType<AuthActions['renewSession']>;

  const getCollabPerms = () => store.getters['auth/userCollaborationPermissions'] as ReturnType<
    AuthGetters['userCollaborationPermissions']
  >;

  router.beforeEach(async (to, from) => {
    if (to.path === Paths.ROOT) {
      return toAuthPage();
    }

    const goingToAuthPage = to.path === `/${Paths.AUTHENTICATION}`;

    if (goingToAuthPage) {
      const authenticated = store.getters['auth/authenticated'] as ReturnType<
        AuthGetters['authenticated']
      >;

      if (authenticated) {
        return toHomePage(getCollabPerms());
      }

      if (to.hash) {
        try {
          // attempt to authenticate the user
          await store.dispatch('auth/handleAuthentication');

          // authentication was successful; send them to the home page
          return toHomePage(getCollabPerms());
        } catch (e: any) {
          logError(e);

          const authError = AuthErrors[e?.errorDescription as keyof typeof AuthErrors];
          const code = authError?.code;
          const prompt = authError?.prompt;

          if (code && prompt) {
            return toAuthPage({ query: { code } });
          }

          return true;
        }
      }
    } else {
      const localStorageProduct = getItemFromLocalStorage<string>(
        LOCAL_STORAGE_SELECTED_PRODUCT_KEY,
      );
      const stateProduct = getItemFromGetters<string>(APP__SELECTED_PRODUCT);

      if (!localStorageProduct || !stateProduct) {
        const routeProduct = getRouteProductName(to);
        const apolloClientProduct = routeProduct
          ? toPortalProduct(routeProduct)
          : PortalProducts.NAVIGATOR;

        if (apolloClientProduct) {
          setItemInLocalStorage<string>(
            LOCAL_STORAGE_SELECTED_PRODUCT_KEY,
            apolloClientProduct,
          );
          setItemInState<string>(APP__SET_SELECTED_PRODUCT, apolloClientProduct);
        }
      }
    }

    try {
      const token = store.getters['auth/accessToken'] as ReturnType<AuthGetters['accessToken']>;
      const authenticated = store.dispatch(
        'auth/setIsAuthenticated',
        token,
      ) as ReturnType<AuthActions['setIsAuthenticated']>;
      const isAuthenticated = await authenticated;

      if (token === '' && (!from.name || isAuthenticated)) {
        // access token is not set; renew the session
        const renewSuccess = await renewSession(renewFn);

        if (renewSuccess) {
          return (goingToAuthPage) ? toHomePage(getCollabPerms()) : true;
        }

        return (goingToAuthPage) ? true : toAuthPage();
      }

      if (isAuthenticated) {
        return (goingToAuthPage) ? toHomePage(getCollabPerms()) : true;
      }

      return (goingToAuthPage) ? true : toAuthPage();
    } catch {
      return (goingToAuthPage) ? true : toAuthPage();
    }
  });
}

/**
 * @param _app Vue instance
 * @param options Object
 * {
 *   router: Vue Router instance
 * }
 */
export default {
  install: (_app: App, options: { router: Router; }): void => {
    const { router } = options;

    if (!router) throw Error('Vue Router instance required for authentication');

    setupAuthentication(router);
  },
};
