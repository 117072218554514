import { getValidActiveProducts, type ActiveProduct } from '@/config';
import { LOCAL_STORAGE_SELECTED_PRODUCT_KEY } from '@/config/constants';

import { getItemFromLocalStorage } from './localStorage';
import { removeQuotes } from './strUtils';
import { excludeNullish } from './general';
import { toPortalProduct, isActiveProduct } from './convertPortalProduct';

export function getSelectedProductFromLocalStorage(): string {
  const localSelectedProduct = getItemFromLocalStorage<string>(
    LOCAL_STORAGE_SELECTED_PRODUCT_KEY,
  ) ?? '';

  return removeQuotes(localSelectedProduct);
}

export function initialSelectedProduct(productAccess: string[] = []): ActiveProduct | null {
  const selectedProduct = toPortalProduct(getSelectedProductFromLocalStorage());
  let validProducts = getValidActiveProducts();

  if (productAccess.length) {
    const portalProductAccess = productAccess.map(toPortalProduct).filter(excludeNullish);

    validProducts = validProducts.filter((product) => portalProductAccess.includes(product));
  }

  if (
    selectedProduct
    && isActiveProduct(selectedProduct)
    && validProducts.includes(selectedProduct)
  ) {
    return selectedProduct;
  }

  // get valid products, and by default select the first one
  return validProducts.length ? validProducts[0] : null;
}
