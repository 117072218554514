import { safeStringify, safeParse } from './safeJSON';

export const getItemFromLocalStorage = <T>(key: string): T | null => {
  const retrievedItem = localStorage.getItem(key);

  if (retrievedItem) {
    return safeParse<T>(retrievedItem).unwrapOr(null);
  }

  return null;
};

export const setItemInLocalStorage = <T>(key: string, value: T): string | null => {
  const stringifiedValue = safeStringify(value);

  if (stringifiedValue.isOk()) {
    localStorage.setItem(key, stringifiedValue.value);

    return stringifiedValue.value;
  }

  // TODO: handle and log error
  return null;
};

export function listenForLocalStorageChanges(
  key: string,
  callback: (newValue: string | null) => void,
) {
  const storageListener = (event: StorageEvent) => {
    if (event.key === key) {
      callback(event.newValue);
    }
  };

  window.addEventListener('storage', storageListener);

  return () => window.removeEventListener('storage', storageListener);
}
