async function enableEditorDevTools(editorInstance: unknown): Promise<void> {
  if (process.env.NODE_ENV === 'development') {
    try {
      const CKEditorInspector = await import('@ckeditor/ckeditor5-inspector');

      CKEditorInspector.default.attach(editorInstance);
    } catch {
      // TODO: log import error
    }
  }
}

export default enableEditorDevTools;
