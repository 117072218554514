import { ApolloError } from '@apollo/client';
import { computed } from 'vue';
import type { Ref, ComputedRef } from 'vue';

import useProductQuery from './useProductQuery';

import DataImportFilesJurisdictionsQuery from '@/shared/graphql/query/DataImportFilesJurisdictions.gql';
import type {
  DataImportFilesJurisdictionsQuery as DataImportFilesJurisdictionsQueryResultType,
  Location,
} from '@/generated/shared-graphql';
import type { LabelValue } from '@/types/utilityTypes';

export type DataImportFilesJurisdictionsQueryResult = {
  dataImportFilesJurisdictions: ComputedRef<Readonly<Location[]>>;
  loading: Ref<boolean>;
  error: Ref<ApolloError | null>;
}

interface DataImportFilesJurisdictionsComposable {
  fetchDataImportFilesJurisdictions: () => DataImportFilesJurisdictionsQueryResult;
  mapDataImportFilesJurisdictions: (
    jurisdictions: Readonly<Location[]>
  ) => LabelValue[];
}

const useDataImportFilesJurisdictions = (): DataImportFilesJurisdictionsComposable => {
  const fetchDataImportFilesJurisdictions = (): DataImportFilesJurisdictionsQueryResult => {
    const { result, loading, error } = useProductQuery<
      DataImportFilesJurisdictionsQueryResultType
    >(DataImportFilesJurisdictionsQuery, null, {
      errorPolicy: 'all', fetchPolicy: 'network-only',
    });
    const dataImportFilesJurisdictions = computed(
      () => result.value?.locations?.filter(Boolean) as Location[] ?? [],
    );

    return {
      dataImportFilesJurisdictions,
      loading,
      error,
    };
  };

  const mapDataImportFilesJurisdictions = (
    jurisdictions: Readonly<Location[]>,
  ): LabelValue[] => {
    const map = jurisdictions.map((jurisdiction) => ({
      value: jurisdiction?.fields?.[0]?.id,
      label: jurisdiction?.fields?.[0]?.name,
    }));

    return map.filter((jurisdiction) => jurisdiction.value) as LabelValue[];
  };

  return {
    fetchDataImportFilesJurisdictions,
    mapDataImportFilesJurisdictions,
  };
};

export default useDataImportFilesJurisdictions;
