import type { StatusesQuery } from '@/generated/shared-graphql';
import { format } from 'date-fns';

export type MappedItem = {
  Jurisdiction: string | null;
  'Display date': string;
  Changes: string | null;
  'Publish status': string;
};

export type MapDataToJSONReturnType = {
  'Status export': MappedItem[];
};

// Utility function to remove HTML tags
const stripHtmlTags = (html: string): string => html.replace(/<\/?[^>]+(>|$)/g, '');

const mapDataToJSON = (data: StatusesQuery): MapDataToJSONReturnType => {
  const { edges } = data.statusFieldsesConnection;

  const results = edges?.reduce<MappedItem[]>((accumulator, edge) => {
    if (!edge) {
      return accumulator;
    }

    const result: MappedItem = {
      Jurisdiction: edge.node.locations?.[0]?.fields?.[0]?.name || null,
      'Display date': format(new Date(edge.node.date ?? new Date()), `${edge.node.adHoc ? 'do' : ''} MMMM yyyy`).trim(),
      Changes: edge.node.changes ? stripHtmlTags(edge.node.changes) : null,
      'Publish status': edge.node.published ? 'Published' : 'Not published',
    };

    return accumulator.concat(result);
  }, []) ?? [];

  return { 'Status export': results };
};

export default mapDataToJSON;
