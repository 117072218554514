import type { RouteLocationNormalized } from 'vue-router';
import type { RouteRecordComposite } from '@/types/vueRouter';

export function getRouteNames(
  routesList: RouteRecordComposite[],
): string[] {
  return routesList.reduce((acc, { name }) => (
    name && typeof name === 'string'
      ? [...acc, name]
      : acc
  ), [] as string[]);
}

export const getRouteProductName = (to: RouteLocationNormalized): string => (
  to.fullPath.split('/')[1]?.replace(/['"]+/g, '') ?? ''
);
