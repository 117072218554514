import { Views } from '@/router/router.config';
import type { PlatformProduct, PortalProduct } from './index';

export const DEFAULT_PRODUCT = 'navigator-portal';
export const LOCAL_STORAGE_SELECTED_PRODUCT_KEY = 'ppp_selected_product';
export const LOCAL_STORAGE_SELECTED_LANGUAGE_KEY = 'ppp_language';
export const LANG_KEY = 'ppp_language';

export const PlatformProducts = Object.freeze({
  CRYPTO: 'crypto',
  NAVIGATOR: 'navigator',
  XBTECH: 'xbtech',
  BARCLAYS: 'barclays',
  HEADWINDS: 'headwinds',
});

export const PortalProducts = Object.freeze({
  CRYPTO: 'crypto-portal',
  NAVIGATOR: 'navigator-portal',
  XBTECH: 'xbtech-portal',
  BARCLAYS: 'barclays-portal',
  HEADWINDS: 'headwinds-portal',
});

export const ProductLabels = Object.freeze({
  [PortalProducts.CRYPTO]: 'Crypto',
  [PortalProducts.NAVIGATOR]: 'Navigator',
  [PortalProducts.XBTECH]: 'XBTech',
  [PortalProducts.BARCLAYS]: 'Barclays',
  [PortalProducts.HEADWINDS]: 'Headwinds',
});

export const AllPlatformProducts = <const>[
  PlatformProducts.NAVIGATOR,
  PlatformProducts.CRYPTO,
  PlatformProducts.XBTECH,
  PlatformProducts.BARCLAYS,
  PlatformProducts.HEADWINDS,
] satisfies readonly PlatformProduct[];

export const AllProducts = <const>[
  PortalProducts.NAVIGATOR,
  PortalProducts.CRYPTO,
  PortalProducts.XBTECH,
  PortalProducts.BARCLAYS,
  PortalProducts.HEADWINDS,
] satisfies readonly PortalProduct[];

export const ActivePlatformProducts = <const>[
  PlatformProducts.NAVIGATOR,
  PlatformProducts.CRYPTO,
  PlatformProducts.XBTECH,
  PlatformProducts.BARCLAYS,
  PlatformProducts.HEADWINDS,
] satisfies readonly PlatformProduct[];

export const ActiveProducts = <const>[
  PortalProducts.NAVIGATOR,
  PortalProducts.CRYPTO,
  PortalProducts.XBTECH,
  PortalProducts.BARCLAYS,
  PortalProducts.HEADWINDS,
] satisfies readonly PortalProduct[];

export const routePagesInMenu = <const>[
  Views.DASHBOARD,
  Views.UPDATE_CYCLE_MANAGE,
  Views.CONTACTS,
  Views.AUDIT_HISTORY,
  Views.JURISDICTION_MANAGEMENT,
  Views.GLOBAL_DEFINITIONS,
  Views.LOCAL_DEFINITIONS,
  Views.EEA_DEFINITIONS,
  Views.SUPPORTING_DOCS,
  Views.LEGENDS,
  Views.STATUS_TABLE,
  Views.DATA_EXPORT,
  Views.DATA_IMPORT,
] satisfies readonly string[];

export const menuRouteLabels = Object.freeze({
  [Views.DASHBOARD]: 'Dashboard',
  [Views.UPDATE_CYCLE_MANAGE]: 'Manage update cycle',
  [Views.CONTACTS]: 'Contacts',
  [Views.AUDIT_HISTORY]: 'Audit history',
  [Views.JURISDICTION_MANAGEMENT]: 'Jurisdiction management',
  [Views.GLOBAL_DEFINITIONS]: 'Global definitions',
  [Views.LOCAL_DEFINITIONS]: 'Local definitions',
  [Views.EEA_DEFINITIONS]: 'EEA definitions',
  [Views.SUPPORTING_DOCS]: 'Supporting documents',
  [Views.LEGENDS]: 'Legends',
  [Views.STATUS_TABLE]: 'Status table',
  [Views.DATA_EXPORT]: 'Data export',
  [Views.DATA_IMPORT]: 'Data import',
});

export const sidebarWidths = Object.freeze({
  closed: '0px',
  opened: '50px',
  hovered: '250px',
});

export const tableCellsText = Object.freeze({
  publish: {
    scheduledDate: 'No scheduled date',
    owner: 'No owner',
  },
  jurisdictionManagement: {
    owner: 'No owner',
    jurisdiction: 'No jurisdiction',
  },
  documentCycle: {
    owner: 'No owner',
    dueDate: 'No due date',
    publishedDate: 'No published date',
  },
  definition: {
    scheduledDate: 'No scheduled date',
  },
  status: {
    scheduledDate: 'No scheduled date',
  },
});

export const AuthErrors = Object.freeze({
  please_reset_your_password: {
    code: 3,
    prompt: true,
  },
  'Please verify your email before logging in.': {
    code: 4,
    prompt: true,
  },
});

export const ProgressStatuses = Object.freeze({
  ABANDONED: 'Abandoned',
  ACTIVE_COUNSEL: 'Active Counsel',
  ACTIVE_SIMMONS: 'Active Simmons',
  APPROVED: 'Approved',
  PREP: 'Preparation',
  PUBLISHED: 'Published',
});

export const ReviewGuidanceProgressStatusesForUpdate = Object.freeze({
  ACTIVE_COUNSEL: 0,
  ACTIVE_SIMMONS: 1,
  PREP: 2,
});
