const isChrome = (): boolean => {
  const { navigator } = window;
  const { userAgent, vendor } = navigator;

  const isChromium = 'chrome' in window;
  const isOpera = 'opr' in window;
  const isIEdge = userAgent.indexOf('Edg') > -1;
  const isIOSChrome = userAgent.match('CriOS');

  if (isOpera || isIEdge) {
    return false;
  }

  if (isIOSChrome) {
    return true;
  }

  return isChromium && vendor === 'Google Inc.';
};

export default isChrome;
