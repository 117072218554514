import type {
  DefinitionFieldsUpdateManyMutationInput,
  UpdateDefinitionFieldsesMutation,
  UpdateDefinitionFieldsesMutationVariables,
} from '@/generated/shared-graphql';

import UpdateDefinitionFieldsesQuery from '@/shared/graphql/mutation/UpdateDefinitionFieldses.gql';

import useProductMutation from '@/composables/useProductMutation';

export const useUpdateDefinitionsFieldses = () => useProductMutation<
  UpdateDefinitionFieldsesMutation,
  UpdateDefinitionFieldsesMutationVariables
>(UpdateDefinitionFieldsesQuery);

export const useUpdateDefinitionsFieldsesByIds = () => {
  const t = useUpdateDefinitionsFieldses();

  const mutate = (ids: string[], data: DefinitionFieldsUpdateManyMutationInput) => {
    const variables: UpdateDefinitionFieldsesMutationVariables = {
      where: {
        id: {
          in: ids,
        },
      },
      data,
    };

    return t.mutate(variables);
  };

  return {
    ...t,
    mutate,
  };
};

export default useUpdateDefinitionsFieldses;
