import router from '@/router';
import { Views } from '@/router/router.config';

import { useStore } from '@/store';
import { APP__ADD_SNACKBAR } from '@/store/modules/app/mutations';

function navigateToDashboardProcessing() {
  const { useMutation } = useStore();

  // Display snackbar message
  useMutation(APP__ADD_SNACKBAR, {
    messageContent: 'Document is currently processing - please try again later.',
    timeout: 3,
    type: 'warning',
  });

  // Navigate user to dashboard
  const currentRoute = router.currentRoute.value.name as string;
  const [selectedProduct] = currentRoute.split(':');

  router.push({
    name: `${selectedProduct}:${Views.DASHBOARD}`,
  });
}

export default navigateToDashboardProcessing;
