import xlsxOptions from '@/config/export.config';
import { json } from '@simmons/file-conversion';

const getXlsxFromJson = async (
  content,
  filename = 'file.xlsx',
  contentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8;',
) => {
  const xlsxContent = await json.toXlsx(content, xlsxOptions);
  // Create a blob
  const blob = new Blob([xlsxContent], { type: contentType });
  const url = URL.createObjectURL(blob);

  // Create a link to download it
  const pom = document.createElement('a');
  pom.href = url;
  pom.setAttribute('download', filename);
  pom.click();
  pom.remove();
};

export default getXlsxFromJson;
