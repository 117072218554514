import { some } from 'lodash-es';
import type { RouteRecordRaw } from 'vue-router';

import { routes } from '@/router';

const getAllRoutes = (): RouteRecordRaw[] => {
  const allRouteRecords: RouteRecordRaw[] = [];

  const recursiveFindAllRoutes = (routeRecord: RouteRecordRaw): void => {
    const alreadyHasRecord = some(allRouteRecords, ['name', routeRecord.name]);

    if (!alreadyHasRecord) {
      allRouteRecords.push(routeRecord);
    }

    if (routeRecord.children?.length) {
      routeRecord.children.forEach(recursiveFindAllRoutes);
    }
  };

  routes.forEach(recursiveFindAllRoutes);

  return allRouteRecords;
};

export default getAllRoutes;
