import {
  UpdateDefinitionMutation,
  UpdateDefinitionMutationVariables,
  DefinitionWhereUniqueInput,
} from '@/generated/shared-graphql';
import UpdateDefinition from '@/shared/graphql/mutation/UpdateDefinition.gql';

import useProductMutation from '@/composables/useProductMutation';

const useUpdateDefinition = () => useProductMutation<
  UpdateDefinitionMutation,
  UpdateDefinitionMutationVariables
>(UpdateDefinition);

type UpdateType = NonNullable<NonNullable<
  UpdateDefinitionMutationVariables['data']['fields']
>['update']>[number]['data'];

export const useUpdateDefinitionFieldsById = <T extends UpdateType = UpdateType>() => {
  const t = useUpdateDefinition();

  const mutate = (id: DefinitionWhereUniqueInput['id'], values: T) => {
    const variables: UpdateDefinitionMutationVariables = {
      where: {
        id,
      },
      data: {
        fields: {
          update: [
            {
              data: values,
              where: { id },
            },
          ],
        },
      },
    };

    return t.mutate(variables);
  };

  return {
    ...t,
    mutate,
  };
};

export default useUpdateDefinition;
