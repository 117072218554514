import { differenceInCalendarDays, getYear } from 'date-fns';
import { helpers } from '@vuelidate/validators';
import { unref, type Ref } from 'vue';
import type { ValidatorFn, ValidationArgs, ValidationRule } from '@vuelidate/core';

export type FilterDatesData = {
  from: Date,
  to: Date,
};

export type FilterDatesValidationArgs = Partial<
  Record<keyof FilterDatesData, ValidationRule | ValidationArgs>
>;

export type ScheduledDateValidation = {
  scheduledDate: Date;
};

export type ScheduledDateValidationArgs = Partial<
  Record<keyof ScheduledDateValidation, ValidationRule | ValidationArgs>
>;

export function isFurtherDay(compareWith: Date | Ref<Date>) {
  return (date: Date) => {
    const compare = unref(compareWith);
    const daysDiff = differenceInCalendarDays(date, compare);

    return daysDiff >= 1;
  };
}

export function isYearValid(date: Date) {
  const valid = 2000;
  const year = getYear(date);

  return year > valid;
}

export const validateDate: ValidatorFn<
  Date,
  FilterDatesData | Ref<FilterDatesData>
> = (_, dates) => {
  const compare = unref(dates);
  const daysDiff = differenceInCalendarDays(compare.to, compare.from);

  return daysDiff >= 0;
};

export const getFilterDatesValidation: FilterDatesValidationArgs = {
  from: {
    isDateLessOrEqual: helpers.withMessage(
      'Please select date that is less or equal to date',
      validateDate,
    ),
  },
  to: {
    isDateLessOrEqual: helpers.withMessage(
      'Please select date that is greater or equal from date',
      validateDate,
    ),
  },
};

const isGreaterThanCurrent: ValidatorFn<Date, ScheduledDateValidation> = (scheduledDate) => {
  const validate = isFurtherDay(new Date());

  return validate(scheduledDate);
};

export const getScheduledDateValidation: ScheduledDateValidationArgs = {
  scheduledDate: {
    isGreaterThanCurrent: helpers.withMessage(
      'Please select a date that is in the future',
      isGreaterThanCurrent,
    ),
  },
};
