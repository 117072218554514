import type { GetterTree } from 'vuex';

import type { ActiveProduct } from '@/config';
import type { RootState } from '@/store/state';
import type { State } from './state';

export const APP__CLIENT = 'APP__CLIENT';
export const APP__CLIENT_ENDPOINT = 'APP__CLIENT_ENDPOINT';
export const APP__DARK_MODE = 'APP__DARK_MODE';
export const APP__SELECTED_PRODUCT = 'APP__SELECTED_PRODUCT';
export const APP__SNACKBAR = 'APP__SNACKBAR';

// getters types
export type Getters = {
  [APP__CLIENT](state: State): string;
  [APP__CLIENT_ENDPOINT](state: State): string;
  [APP__DARK_MODE](state: State): boolean;
  [APP__SELECTED_PRODUCT](state: State): ActiveProduct | null;
  [APP__SNACKBAR](state: State): State['snackbars'];
};

// getters
export const getters: GetterTree<State, RootState> & Getters = {
  [APP__CLIENT]: (state) => state.client,
  [APP__CLIENT_ENDPOINT]: (state) => state.clientEndpoint,
  [APP__DARK_MODE]: (state) => state.darkMode,
  [APP__SELECTED_PRODUCT]: (state) => state.selectedProduct,
  [APP__SNACKBAR]: (state) => state.snackbars,
};
