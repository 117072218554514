import auth from '@simmons/auth-store';

export type {
  AuthGetters,
  AuthActions,
  AuthMutations,
  AuthUser,
  State,
} from './auth.types';
export default auth;
