import { unref } from 'vue';
import { useQuery } from '@vue/apollo-composable';
import type { DocumentNode, OperationVariables } from '@apollo/client';

import useSelectedProduct from '@/composables/useSelectedProduct';

import type {
  PrimitiveValue,
  PrimitiveValueVec,
} from '@/types/utilityTypes';
import type {
  VariablesParameter,
} from '@/types/schemaTypes';

type QueriesMap = {
  [key: string]: DocumentNode;
};
type UseQueryDoc = Parameters<typeof useQuery>[0];
type UseQueryOptions = Parameters<typeof useQuery>[1];

/**
 *
 * @param queries The queries object
 * @throws Error if no product is selected
 * @returns The query for the selected product
 */
export function getProductQuery(queries: QueriesMap): DocumentNode {
  const { selectedProduct } = useSelectedProduct();
  const product = unref(selectedProduct);

  if (product) {
    return queries[product];
  }

  throw Error('No product selected');
}

function useProductQuery<
  TResult = any,
  TVariables = Record<string, PrimitiveValue | PrimitiveValueVec> | OperationVariables,
  TOptions = UseQueryOptions
>(
  query: UseQueryDoc,
  variables?: undefined | null | VariablesParameter<TVariables>,
  options?: TOptions,
) {
  const { selectedProduct } = useSelectedProduct();
  const product = unref(selectedProduct);
  const clientId = product || 'default';

  return (!variables)
    ? useQuery<TResult>(query, null, {
      errorPolicy: 'all',
      fetchPolicy: 'cache-and-network',
      ...options,
      clientId,
    })
    : useQuery<TResult, OperationVariables>(query, variables, {
      errorPolicy: 'all',
      fetchPolicy: 'cache-and-network',
      ...options,
      clientId,
    });
}

export default useProductQuery;
