import { useScroll } from '@vueuse/core';
import {
  computed,
  ref,
  unref,
} from 'vue';
import type { Ref } from 'vue';

type ContainerType = Ref<Element | undefined> | Element | undefined;
type SectionElementsType = Ref<Array<Element>> | Array<Element>;
type OffsetType = Ref<number> | number;

function getIndexInView(elements: Array<Element>, scrollTop: number, offset: number) {
  return elements.findIndex((element, i) => {
    const nextElement = elements[i + 1];

    if (!(element instanceof HTMLElement)) {
      return false;
    }

    const nextElementOffsetTop = !nextElement || !(nextElement instanceof HTMLElement)
      ? Infinity
      : nextElement.offsetTop;

    const startAt = element.offsetTop;
    const endAt = nextElementOffsetTop;

    const currentPosition = scrollTop + offset;
    const isInView = currentPosition > startAt && currentPosition <= endAt;

    return isInView;
  });
}

const useScrollSpy = (
  scrollableContainer: ContainerType,
  sectionElements: SectionElementsType = [],
  offset: OffsetType = 0,
): Ref<number> => {
  const activeElementIndex = ref<number>(0);
  let scrollTop = ref(0);

  const container = computed(() => {
    const element = unref(scrollableContainer);
    if (element instanceof HTMLElement) {
      return element;
    }

    return undefined;
  });

  const handleFrame = () => {
    const elements = unref(sectionElements);
    const theOffset = unref(offset);

    if (!container.value) {
      return;
    }

    const indexInView = getIndexInView(elements, scrollTop.value, theOffset);
    activeElementIndex.value = indexInView;
  };

  ({ y: scrollTop } = useScroll(container, {
    onScroll: () => requestAnimationFrame(handleFrame),
  }));

  return activeElementIndex;
};

export default useScrollSpy;
