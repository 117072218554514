import {
  differenceInSeconds,
  formatISO,
  isSameSecond,
  parseISO,
} from 'date-fns';
import { computed, unref, type Ref } from 'vue';

import usePersistentStorage from '@/composables/usePersistentStorage';
import getIndicatorTimeout from './getIndicatorTimeout';

import type {
  MutatingIndicatorStoreType,
  MutatingIndicatorType,
} from './useMutatingIndicators.types';

function useMutatingIndicators(type: MutatingIndicatorType | Ref<MutatingIndicatorType>) {
  const theType = computed(() => unref(type));
  const storageKey = computed<MutatingIndicatorStoreType>(() => `mutating-${theType.value}`);
  const state = usePersistentStorage(storageKey, 1);

  function set(id: string, lastUpdatedAt: Date, localUpdatedAt: Date) {
    if (!state.value) {
      state.value = {};
    }

    state.value = {
      ...state.value,
      [id]: {
        lastUpdatedAt: formatISO(lastUpdatedAt),
        localUpdatedAt: formatISO(localUpdatedAt),
      },
    };
  }

  function remove(id: string) {
    if (!state.value) {
      return;
    }

    const idInState = id in state.value;

    if (!idInState) {
      return;
    }

    const newState = { ...state.value };

    delete newState[id];

    state.value = newState;
  }

  function privateIsMutating(id: string, updatedAt: Date): boolean {
    const lastUpdatedAtString = state.value?.[id]?.lastUpdatedAt;

    if (!lastUpdatedAtString) {
      return false;
    }

    const lastUpdatedAt = parseISO(lastUpdatedAtString);

    return isSameSecond(lastUpdatedAt, updatedAt);
  }

  function hasError(id: string, updatedAt: Date): boolean {
    const localUpdatedAtString = state.value?.[id]?.localUpdatedAt;

    if (!localUpdatedAtString) {
      return false;
    }

    if (!privateIsMutating(id, updatedAt)) {
      return false;
    }

    const localUpdatedAt = parseISO(localUpdatedAtString);
    const indicatorTimeout = getIndicatorTimeout(theType.value);
    const now = new Date();

    return differenceInSeconds(now, localUpdatedAt) >= indicatorTimeout;
  }

  // `isMutating` can't be true when `hasError` is true
  function isMutating(id: string, updatedAt: Date): boolean {
    if (hasError(id, updatedAt)) {
      return false;
    }

    return privateIsMutating(id, updatedAt);
  }

  return {
    hasError,
    isMutating,
    remove,
    set,
    state,
  };
}

export default useMutatingIndicators;
