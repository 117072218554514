import { App } from 'vue';
import { Router } from 'vue-router';
import worker from '@/worker';

const checkForUpdates = (router: Router) => {
  router.afterEach(async () => {
    await worker.checkForUpdates();
  });
};

export default {
  install: (_app: App, options: { router: Router; }): void => {
    const { router } = options;

    checkForUpdates(router);
  },
};
