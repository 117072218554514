/**
 * isInArray
 * Checks to see if an item exists in the given array
 *
 * @param item The item to find in the array
 * @param array The readonly array of items
 * @returns boolean
 */
function isInArray<T, A extends T>(
  item: T,
  array: ReadonlyArray<A>,
): item is A {
  return array.includes(item as A);
}

export default isInArray;
