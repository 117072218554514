import navigateToDashboardProcessing from './views/document/utils/navigateToDashboardProcessing';

export default class ErrorHandling {
  static init() {
    window.addEventListener('error', (event) => {
      if (event.message.includes('Uncaught CKEditorError: cloudservices-init')) {
        ErrorHandling.handleCKEditorCloudInitError();
      }

      if (event.message.includes('CKEditorError: cloud-services-internal-error')) {
        ErrorHandling.handleCKEditorCorruptedSession();
      }
    });
  }

  static handleCKEditorCloudInitError() {
    navigateToDashboardProcessing();
  }

  static handleCKEditorCorruptedSession() {
    navigateToDashboardProcessing();
  }
}
