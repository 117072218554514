import type {
  ActiveProduct,
  PlatformProduct,
  PortalProduct,
} from '@/config';
import {
  ActiveProducts,
  AllPlatformProducts,
  AllProducts,
  PlatformProducts,
  PortalProducts,
} from '@/config/constants';
import isInArray from './isInArray';

export function isPlatformProduct(value: string): value is PlatformProduct {
  return (
    Object.values<string>(PlatformProducts).includes(value)
    && isInArray<string, PlatformProduct>(value, AllPlatformProducts)
  );
}

export function isPortalProduct(value: string): value is PortalProduct {
  return (
    Object.values<string>(PortalProducts).includes(value)
    && isInArray<string, PortalProduct>(value, AllProducts)
  );
}

export function isActiveProduct(value: string): value is ActiveProduct {
  return (
    (AllProducts as Readonly<string[]>).includes(value)
    && isInArray<string, ActiveProduct>(value, ActiveProducts)
  );
}

export function toPortalProduct(value: string): PortalProduct | null {
  if (isPortalProduct(value)) {
    return value;
  }
  if (!value.endsWith('-portal')) {
    const withPortalSuffix = `${value}-portal`;

    return isPortalProduct(withPortalSuffix) ? withPortalSuffix : null;
  }

  return null;
}

export function removePortalSuffix(product: PortalProduct): PlatformProduct | null {
  if (isPlatformProduct(product)) {
    return product;
  }

  const withoutPortalSuffix = product.replace('-portal', '') as PlatformProduct;

  return isPlatformProduct(withoutPortalSuffix) ? withoutPortalSuffix : null;
}

export function portalProductToString(product: PortalProduct | null): string {
  return (product !== null) ? product as string : '';
}
