import {
  AuditHistoryIcon,
  DataExportIcon,
  DataImportIcon,
  EeaDefinitionIcon,
  GlobalDefinitionIcon,
  JurisdictionManagementIcon,
  LocalDefinitionIcon,
} from '@/assets/icons';
import { Paths, Views } from '@/router/router.config';
import { RouteRecordRaw } from 'vue-router';

import { getProductPermissions } from '@/config';
import { PortalProducts } from '@/config/constants';

const {
  ownerPermissions,
} = getProductPermissions(PortalProducts.BARCLAYS);

const barclaysRoutes: Array<RouteRecordRaw> = [
  {
    path: `/${Paths.BARCLAYS}`,
    name: PortalProducts.BARCLAYS,
    component: () => import(/* webpackChunkName: "barclays" */'@/products/barclays/BarclaysLayout.vue'),
    redirect: () => ({ name: `${PortalProducts.BARCLAYS}:${Views.LOCAL_DEFINITIONS}` }),
    meta: {
      requiredPermissions: [...ownerPermissions],
    },
    children: [
      {
        path: Paths.LOCAL_DEFINITIONS,
        name: `${PortalProducts.BARCLAYS}:${Views.LOCAL_DEFINITIONS}`,
        redirect: () => ({ name: `${PortalProducts.BARCLAYS}:${Views.LOCAL_DEFINITIONS_LIST}` }),
        component: () => import(/* webpackChunkName: "local-definitions-view" */'@/views/local-definitions/LocalDefinitions.vue'),
        meta: {
          requiredPermissions: [...ownerPermissions],
          icon: LocalDefinitionIcon,
        },
        children: [
          {
            path: Paths.LIST,
            name: `${PortalProducts.BARCLAYS}:${Views.LOCAL_DEFINITIONS_LIST}`,
            component: () => import(/* webpackChunkName: "list-local-definitions-view" */'@/views/local-definitions/ListLocalDefinitions.vue'),
            meta: {
              requiredPermissions: [...ownerPermissions],
            },
          },
          {
            path: Paths.CREATE,
            name: `${PortalProducts.BARCLAYS}:${Views.LOCAL_DEFINITIONS_CREATE}`,
            component: () => import(/* webpackChunkName: "create-local-definitions-view" */'@/views/local-definitions/CreateLocalDefinition.vue'),
            meta: {
              requiredPermissions: [...ownerPermissions],
            },
          },
        ],
      },
      {
        path: Paths.GLOBAL_DEFINITIONS,
        name: `${PortalProducts.BARCLAYS}:${Views.GLOBAL_DEFINITIONS}`,
        redirect: () => ({ name: `${PortalProducts.BARCLAYS}:${Views.GLOBAL_DEFINITIONS_LIST}` }),
        component: () => import(/* webpackChunkName: "barclays-global-definitions-view" */'@/views/global-definitions/GlobalDefinitions.vue'),
        meta: {
          requiredPermissions: [...ownerPermissions],
          icon: GlobalDefinitionIcon,
        },
        children: [
          {
            path: Paths.LIST,
            name: `${PortalProducts.BARCLAYS}:${Views.GLOBAL_DEFINITIONS_LIST}`,
            component: () => import(/* webpackChunkName: "barclays-list-global-definitions-view" */'@/views/global-definitions/pages/list'),
            meta: {
              requiredPermissions: [...ownerPermissions],
            },
          },
          {
            path: Paths.CREATE,
            name: `${PortalProducts.BARCLAYS}:${Views.GLOBAL_DEFINITIONS_CREATE}`,
            component: () => import(/* webpackChunkName: "barclays-create-global-definitions-view" */'@/views/global-definitions/pages/create'),
            meta: {
              requiredPermissions: [...ownerPermissions],
            },
          },
          {
            path: `${Paths.EDIT}/:definitionId`,
            name: `${PortalProducts.BARCLAYS}:${Views.GLOBAL_DEFINITIONS_EDIT}`,
            component: () => import(/* webpackChunkName: "barclays-edit-global-definitions-view" */'@/views/global-definitions/pages/edit'),
            meta: {
              requiredPermissions: ['barclays-collaboration-owner'],
            },
          },
        ],
      },
      {
        path: Paths.EEA_DEFINITIONS,
        name: `${PortalProducts.BARCLAYS}:${Views.EEA_DEFINITIONS}`,
        redirect: () => ({ name: `${PortalProducts.BARCLAYS}:${Views.EEA_DEFINITIONS_LIST}` }),
        component: () => import(/* webpackChunkName: "eea-definitions-view" */'@/views/eea-definitions/EEADefinitions.vue'),
        meta: {
          requiredPermissions: ['barclays-collaboration-owner'],
          icon: EeaDefinitionIcon,
        },
        children: [
          {
            path: Paths.LIST,
            name: `${PortalProducts.BARCLAYS}:${Views.EEA_DEFINITIONS_LIST}`,
            component: () => import(/* webpackChunkName: "list-eea-definitions-view" */'@/views/eea-definitions/pages/list/ListEEADefinitions.vue'),
            meta: {
              requiredPermissions: ['barclays-collaboration-owner'],
            },
          },
          {
            path: Paths.CREATE,
            name: `${PortalProducts.BARCLAYS}:${Views.EEA_DEFINITIONS_CREATE}`,
            component: () => import(/* webpackChunkName: "create-eea-definitions-view" */'@/views/eea-definitions/pages/create/CreateEEADefinition.vue'),
            meta: {
              requiredPermissions: ['barclays-collaboration-owner'],
            },
          },
          {
            path: `${Paths.EDIT}/:definitionId`,
            name: `${PortalProducts.BARCLAYS}:${Views.EEA_DEFINITIONS_EDIT}`,
            component: () => import(/* webpackChunkName: "edit-eea-definitions-view" */'@/views/eea-definitions/pages/edit/EditEEADefinition.vue'),
            meta: {
              requiredPermissions: [...ownerPermissions],
            },
          },
        ],
      },
      {
        path: Paths.JURISDICTION_MANAGEMENT,
        name: `${PortalProducts.BARCLAYS}:${Views.JURISDICTION_MANAGEMENT}`,
        redirect: () => ({ name: `${PortalProducts.BARCLAYS}:${Views.JURISDICTIONS_LIST}` }),
        component: () => import(/* webpackChunkName: "jurisdiction-management-view" */ '@/views/jurisdiction-management/JurisdictionManagement.vue'),
        meta: {
          requiredPermissions: [...ownerPermissions],
          icon: JurisdictionManagementIcon,
        },
        children: [
          {
            path: Paths.LIST,
            name: `${PortalProducts.BARCLAYS}:${Views.JURISDICTIONS_LIST}`,
            component: () => import(/* webpackChunkName: "list-jurisdictions-view" */'@/views/jurisdiction-management/ListJurisdictions.vue'),
            meta: {
              requiredPermissions: [...ownerPermissions],
            },
          },
          {
            path: Paths.CREATE,
            name: `${PortalProducts.BARCLAYS}:${Views.JURISDICTIONS_CREATE}`,
            component: () => import(/* webpackChunkName: "create-jurisdiction-form" */'@/views/jurisdiction-management/CreateJurisdiction.vue'),
            meta: {
              requiredPermissions: [...ownerPermissions],
            },
          },
        ],
      },
      {
        path: Paths.AUDIT_HISTORY,
        name: `${PortalProducts.BARCLAYS}:${Views.AUDIT_HISTORY}`,
        component: () => import(/* webpackChunkName: "audit-history-view" */ '@/views/audit-history/AuditHistory.vue'),
        meta: {
          requiredPermissions: [...ownerPermissions],
          icon: AuditHistoryIcon,
        },
      },
      {
        path: Paths.DATA_EXPORT,
        name: `${PortalProducts.BARCLAYS}:${Views.DATA_EXPORT}`,
        component: () => import(/* webpackChunkName: "audit-history-view" */'@/views/data-export/DataExport.vue'),
        meta: {
          requiredPermissions: [...ownerPermissions],
          icon: DataExportIcon,
        },
      },
      {
        path: Paths.DATA_IMPORT,
        name: `${PortalProducts.BARCLAYS}:${Views.DATA_IMPORT}`,
        component: () => import(/* webpackChunkName: "data-import-view" */'@/views/data-import/DataImport.vue'),
        redirect: () => ({ name: `${PortalProducts.BARCLAYS}:${Views.DATA_IMPORT_LIST}` }),
        meta: {
          requiredPermissions: [...ownerPermissions],
          icon: DataImportIcon,
        },
        children: [
          {
            path: Paths.DATA_IMPORT_LIST,
            name: `${PortalProducts.BARCLAYS}:${Views.DATA_IMPORT_LIST}`,
            component: () => import(/* webpackChunkName: "list-data-import-view" */'@/views/data-import/ListDataImport.vue'),
            meta: {
              requiredPermissions: [...ownerPermissions],
            },
          },
          {
            path: `${Paths.DATA_IMPORT_PUBLISH}/:id`,
            name: `${PortalProducts.BARCLAYS}:${Views.DATA_IMPORT_PUBLISH}`,
            component: () => import(/* webpackChunkName: "publish-file-import-view" */ '@/views/data-import/publish'),
            meta: {
              requiredPermissions: [...ownerPermissions],
            },
          },
          {
            path: Paths.DATA_IMPORT_CREATE,
            name: `${PortalProducts.BARCLAYS}:${Views.DATA_IMPORT_CREATE}`,
            component: () => import(/* webpackChunkName: "new-data-import-view" */'@/views/data-import/NewDataImport.vue'),
            meta: {
              requiredPermissions: [...ownerPermissions],
            },
          },
          {
            path: `${Paths.FILE_IMPORT_VALIDATION_REPORT}/:id`,
            name: `${PortalProducts.BARCLAYS}:${Views.FILE_IMPORT_VALIDATION_REPORT}`,
            component: () => import(/* webpackChunkName: "file-import-report-view" */ '@/views/data-import/components/ValidationReport'),
            meta: {
              requiredPermissions: [...ownerPermissions],
            },
          },
          {
            path: `${Paths.FILE_IMPORT_PREAUDIT_REPORT}/:id`,
            name: `${PortalProducts.BARCLAYS}:${Views.FILE_IMPORT_PREAUDIT_REPORT}`,
            component: () => import(/* webpackChunkName: "file-import-report-view" */ '@/views/data-import/components/AuditReports'),
            meta: {
              requiredPermissions: [...ownerPermissions],
              auditType: 'Pre-Audit',
            },
          },
          {
            path: `${Paths.FILE_IMPORT_POSTAUDIT_REPORT}/:id`,
            name: `${PortalProducts.BARCLAYS}:${Views.FILE_IMPORT_POSTAUDIT_REPORT}`,
            component: () => import(/* webpackChunkName: "file-import-report-view" */ '@/views/data-import/components/AuditReports'),
            meta: {
              requiredPermissions: [...ownerPermissions],
              auditType: 'Post-Audit',
            },
          },
        ],
      },
    ],
  },
];

export default barclaysRoutes;
