import CreateDefinitionMutationQuery from '@/shared/graphql/mutation/CreateDefinition.gql';
import type {
  CreateDefinitionMutation as CreateDefinitionMutationQueryType,
  CreateDefinitionMutationVariables,
  DefinitionFieldsCreateWithoutDefinitionInput,
} from '@/generated/shared-graphql';

import useProductMutation from './useProductMutation';

const useCreateDefinitionFields = () => {
  const createDefinitionReturn = useProductMutation<
    CreateDefinitionMutationQueryType,
    CreateDefinitionMutationVariables
  >(CreateDefinitionMutationQuery);

  const mutate = (
    values:
      DefinitionFieldsCreateWithoutDefinitionInput[],
  ) => {
    const variables: CreateDefinitionMutationVariables = {
      data: {
        fields: {
          create: values,
        },
      },
    };

    return createDefinitionReturn.mutate(variables);
  };

  return {
    ...createDefinitionReturn,
    mutate,
  };
};

export default useCreateDefinitionFields;
