import {
  computed,
  onBeforeUnmount,
  onMounted,
  unref,
  type Ref,
  type WritableComputedRef,
} from 'vue';

import { useStore } from '@/store';
import { LocalStorageGetters, getters } from '@/store/modules/localStorage/getters';
import {
  MutationPayloads,
  LOCAL_STORAGE_SET,
  LOCAL_STORAGE_REFRESH,
} from '@/store/modules/localStorage/mutations';

import type {
  MutatingIndicatorsStoreState,
  MutatingIndicatorStoreType,
} from '@/composables/mutating-indicators';
import { safeParse, safeStringify } from '@/utils/safeJSON';
import { listenForLocalStorageChanges } from '@/utils/localStorage';

// Used to easily clear user's local storage when structure of data, stored in it will change
// In `usePersistent` call we'll just have to bump the version of data (sort of
// major update by semantic versioning) and user's store will be ignored.
const VERSION_KEY = '__version';

type Key<T extends string> = T | Ref<T>;
type Return<T> = WritableComputedRef<T | null>;
type ParsedStore<T> = { [VERSION_KEY]?: number, data?: T; };
type StoreStructure<T> = { [VERSION_KEY]: number, data: T; };

function usePersistentStorage(
  key: Key<MutatingIndicatorStoreType>, version?: number
): Return<MutatingIndicatorsStoreState>;

function usePersistentStorage<T = unknown>(key: Key<string>, version = 1): Return<T> {
  const { useGetter, useMutation } = useStore();
  const theKey = computed(() => unref(key));
  let lsListener: (() => void) | null = null;

  const reactiveValue = computed<T | null>({
    get: () => {
      const strVal = useGetter<ReturnType<typeof getters[LocalStorageGetters.LOCAL_STORAGE_GET]>>(
        LocalStorageGetters.LOCAL_STORAGE_GET,
      )(theKey.value);

      if (!strVal) {
        return null;
      }

      const storedObject = safeParse<ParsedStore<T>>(strVal);

      if (storedObject.isErr()) {
        return null;
      }

      const storedVersion = storedObject.value[VERSION_KEY];

      if (storedVersion === undefined || storedVersion !== version) {
        return null;
      }

      return storedObject.value.data ?? null;
    },
    set: (v) => {
      if (v === null) {
        useMutation<MutationPayloads['LOCAL_STORAGE_SET']>(
          LOCAL_STORAGE_SET,
          { key: theKey.value, value: null },
        );
        return;
      }

      const objectToStore: StoreStructure<T> = { [VERSION_KEY]: version, data: v };
      const strVal = safeStringify(objectToStore);

      if (strVal.isOk()) {
        useMutation<MutationPayloads['LOCAL_STORAGE_SET']>(
          LOCAL_STORAGE_SET,
          { key: theKey.value, value: strVal.value },
        );
      }
    },
  });

  function handleLocalStorageUpdate() {
    useMutation<MutationPayloads['LOCAL_STORAGE_REFRESH']>(
      LOCAL_STORAGE_REFRESH,
      { key: theKey.value },
    );
  }

  onMounted(() => {
    lsListener = listenForLocalStorageChanges(theKey.value, handleLocalStorageUpdate);
  });

  onBeforeUnmount(() => {
    lsListener?.();
  });

  return reactiveValue;
}

export default usePersistentStorage;
