
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'DataExportIcon',
  props: {
    title: {
      type: String,
      required: false,
      default: 'Data export icon',
    },
  },
});
