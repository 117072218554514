import { unref } from 'vue';
import useSelectedProduct from '../composables/useSelectedProduct';

const updateTabTitle = (page: string, product: string | null) => {
  let currentProduct = product;

  if (!product) {
    const { selectedProduct } = useSelectedProduct();
    const currProd = unref(selectedProduct);

    currentProduct = currProd || 'none';
  }

  // Need to handle if the user not yet logged
  if (currentProduct === 'none') {
    currentProduct = '';
  } else {
    currentProduct = ` - ${product}`;
  }

  document.title = `${page}${currentProduct} - Product Platform Portal`;
};

export default updateTabTitle;
