import { createStore, useStore as baseUseStore, type Store } from 'vuex';
import { InjectionKey } from 'vue';

import { auth, app, localStorage } from './modules';
import type { RootState } from './state';

const debug = process.env.NODE_ENV !== 'production';

export const storeKey: InjectionKey<Store<RootState>> = Symbol('RootState');

const store = createStore<RootState>({
  state: {
    appName: 'Product Platform Portal',
  } as RootState,
  modules: {
    auth,
    app,
    localStorage,
  },
  actions: {
    CLEAR_SESSION_DATA() {
      // TODO: nothing
    },
  },
  strict: debug,
});

export function getStore() {
  return store;
}

export function useStore() {
  const useGetter = <T>(
    getter: string,
  ) => getStore().getters[getter] as T;
  const useMutation = <P>(
    mutation: string,
    payload: P,
  ) => getStore().commit(mutation, payload);
  const useAction = <P, R = Promise<any>>(
    action: string,
    payload?: P,
  ): R => {
    const s = getStore();

    if (!s) {
      return {} as R;
    }

    if (payload) {
      return s.dispatch(action, payload) as R;
    }

    return s.dispatch(action) as R;
  };

  return {
    store: baseUseStore(storeKey),
    useGetter,
    useMutation,
    useAction,
  };
}

export default store;
