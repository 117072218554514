/**
 * Given a string will remove any quotes from the string (single or double)
 *
 * @param str String to remove quotes from
 * @returns String with quotes removed
 */
export function removeQuotes(str: string): string {
  return str.replace(/['"]/g, '');
}

/**
 * @param str String to normalise
 * @returns Normalised string i.e. lower case and only alphanumeric and hyphen characters
 */
export function normaliseStr(str: string): string {
  return str
    .toLowerCase()
    .replace(/[^\w\d-]/g, '');
}

/**
 * @param replacement Replacement string
 * @returns Function that takes a search value and a target string and replaces the search value
 * with the replacement string
 */
export function replaceInStr(replacement: string) {
  return (searchVal: string | RegExp) => (targetStr: string) => targetStr.replace(
    searchVal,
    replacement,
  );
}

/**
 * @param searchVal Search value to replace
 * @returns Function that takes a target string and replaces the search value with an underscore
 */
export function replaceWithUnderscore(searchVal: string | RegExp) {
  return replaceInStr('_')(searchVal);
}

/**
 * @param searchVal Search value to replace
 * @returns Function that takes a target string and replaces the search value with a space
 */
export function replaceWithSpace(searchVal: string | RegExp) {
  return replaceInStr(' ')(searchVal);
}

/**
 * @param val Any boolean, number, or string value
 * @returns String representation of the value
 */
export function toString(val?: boolean | number | string): string {
  if (typeof val === 'undefined') return '';

  return (typeof val === 'string') ? val : val.toString();
}
