import { Paths, Views } from './router.config';

export const errorRouteNames = <const>[
  Views.PAGE_NOT_FOUND,
  Views.UNAUTHORISED,
  Views.UNHANDLED,
] satisfies readonly string[];

const errorRoutes = [{
  path: `/${Paths.UNAUTHORISED}`,
  name: Views.UNAUTHORISED,
  component: () => import(/* webpackChunkName: "not-authorised-page" */ '@/views/error/PageUnauthorised.vue'),
  meta: {
    title: '401 Unauthorised',
  },
},
{
  path: `/${Paths.ERROR}`,
  name: Views.UNHANDLED,
  component: () => import(/* webpackChunkName: "unhandled-error-page" */ '@/views/error/PageUnhandledServerError.vue'),
  meta: {
    title: '500 Internal Server Error',
  },
}, {
  path: `/${Paths.PAGE_NOT_FOUND}`,
  name: Views.PAGE_NOT_FOUND,
  component: () => import(/* webpackChunkName: "not-found-page" */ '@/views/error/PageNotFound.vue'),
  meta: {
    title: '404 Page Not Found',
  },
}];

export default errorRoutes;
