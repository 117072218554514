import { computed } from 'vue';
import {
  ApolloLink,
} from '@apollo/client/core';

import router from '@/router';
import { useStore } from '@/store';
import type { AuthGetters } from '@/store/modules/auth/auth.types';

export default function createAuthMiddleware(): ApolloLink {
  const { useGetter } = useStore();
  const authToken = computed(() => useGetter<ReturnType<AuthGetters['token']>>('auth/token'));

  return new ApolloLink((operation, forward) => {
    operation.setContext(({ headers = {} }) => ({
      headers: {
        ...headers,
        'portal-policy': router.currentRoute.value.name,
        authorization: authToken.value ? `Bearer ${authToken.value}` : '',
      },
    }));

    return forward(operation);
  });
}
