import { getStore } from '@/store';

/**
 * Retrieves an item from the Vuex store's getters.
 *
 * (Assumes a getter with the same name as the key exists.)
 *
 * @export
 * @template T
 * @param {string} key
 * @return {*}  {(T | null)}
 */
export function getItemFromGetters<T>(key: string): T | null {
  const store = getStore();

  return key in store.getters ? store.getters[key] : null;
}

export function setItemInState<T>(key: string, value: T): void {
  const store = getStore();

  store.commit(key, value);
}

export default setItemInState;
