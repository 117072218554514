
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'DashboardIcon',
  props: {
    title: {
      type: String,
      required: false,
      default: 'Dashboard icon',
    },
  },
});
