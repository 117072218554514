import type { RouteRecordComposite } from '@/types/vueRouter';

function filterRoutesByProductName(productName: string): (
  routesToFilter: RouteRecordComposite[],
) => RouteRecordComposite[] {
  return (routesToFilter: (RouteRecordComposite)[]): RouteRecordComposite[] => (
    routesToFilter.filter(({ name }) => name && (name as string).startsWith(productName))
  );
}

export default filterRoutesByProductName;
