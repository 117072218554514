import { useStore } from '@/store';

import {
  APP__ADD_SNACKBAR,
  MutationPayloads,
} from '@/store/modules/app/mutations';
import type { State } from '@/store/modules/app/state';

interface Payload {
  messageContent: string,
  timeout?: number,
  classes?: string[],
  icon?: Record<string, unknown>,
  processes?: State['snackbars'][0]['processes'],
}

interface PayloadWithType extends Payload {
  type: string,
}

export interface Snackbar {
  success: (payload: Payload) => void,
  danger: (payload: Payload) => void,
  info: (payload: Payload) => void,
  warning: (payload: Payload) => void,
}

const useSnackbar = (): Snackbar => {
  const { useMutation } = useStore();

  function addSnackbar(payload: Required<PayloadWithType>) {
    useMutation<MutationPayloads['APP__ADD_SNACKBAR']>(
      APP__ADD_SNACKBAR,
      payload,
    );
  }

  function addDefaults(payload: Payload) {
    const defaultValues = {
      timeout: 7,
      classes: [''],
      icon: {},
      processes: [],
    };

    return {
      timeout: payload.timeout || defaultValues.timeout,
      classes: payload.classes || defaultValues.classes,
      icon: payload.icon || defaultValues.icon,
      processes: payload.processes || defaultValues.processes,
    };
  }

  return {
    success: (payload: Payload) => {
      addSnackbar({
        ...addDefaults(payload),
        messageContent: payload.messageContent,
        type: 'success',
      });
    },
    danger: (payload: Payload) => {
      addSnackbar({
        ...addDefaults(payload),
        messageContent: payload.messageContent,
        type: 'danger',
      });
    },
    info: (payload: Payload) => {
      addSnackbar({
        ...addDefaults(payload),
        messageContent: payload.messageContent,
        type: 'info',
      });
    },
    warning: (payload: Payload) => {
      addSnackbar({
        ...addDefaults(payload),
        messageContent: payload.messageContent,
        type: 'warning',
      });
    },
  };
};

export default useSnackbar;
