import { App } from 'vue';
import { Router } from 'vue-router';

import { Paths } from '@/router/router.config';
import { errorRouteNames } from '@/router/errorRoutes';
import { isPortalProduct, removePortalSuffix } from '@/utils/convertPortalProduct';

import { useStore } from '@/store';
import { AuthGetters } from '@/store/modules/auth/auth.types';

import isInArray from '../utils/isInArray';

function checkPermissions(router: Router): void {
  const { useGetter } = useStore();

  router.beforeResolve((to) => {
    const goingToAuthPage = to.path === `/${Paths.AUTHENTICATION}`;
    let routeNameStart = '';

    if (typeof to.name === 'string') {
      ([routeNameStart] = to.name.split(':'));
    }

    if (goingToAuthPage || isInArray(routeNameStart, errorRouteNames)) {
      return true;
    }

    if (routeNameStart && isPortalProduct(routeNameStart)) {
      const product = removePortalSuffix(routeNameStart);
      const userPermissions = useGetter<
        ReturnType<AuthGetters['userCollaborationPermissions']>
      >('auth/userCollaborationPermissions');
      const hasProductPermission = (
        userPermissions.find((p) => (
          p && p.includes(`${product}-collaboration-`)))
      );

      if (!hasProductPermission) {
        throw Error('You do not have permission to view this page!');
      }

      return true;
    }

    throw Error('You do not have permission to view this page!');
  });
}

export default {
  install: (_app: App, options: { router: Router; }): void => {
    const { router } = options;

    checkPermissions(router);
  },
};
